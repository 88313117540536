import GameHistory from 'components/gameHistory/gameHistory';
import { ModalState } from 'context/modal/modalContext';
import ModalContent from '../modalContent/modalContent';

interface GameHistoryModalProps {
  onClose: () => void;
  props: ModalState;
}

const tabMenu = [
  {
    id: '1',
    name: 'gameHistory',
  },
];

function GameHistoryModal({ onClose, props }: GameHistoryModalProps) {
  const children = [{ component: <GameHistory /> }];

  return (
    <ModalContent
      onClose={onClose}
      props={props}
      tabContent={children}
      tabMenu={tabMenu}
      translateFrom={'gameHistory'}
    />
  );
}

export default GameHistoryModal;
