import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import cx from 'classnames';
import styles from './card.module.scss';

interface FaqCardProps {
  title: string;
  description: string;
  img: string;
  index: number;
  isFaqs?: boolean;
}

export default function FaqCard({ title, description, img, index }: FaqCardProps) {
  return (
    <Box className={cx(styles.card)}>
      <Box className={styles.card_media}>
        <img width="260" src={img} alt="cardImage" />
      </Box>
      <Box className={styles.card_content}>
        <Typography gutterBottom component="h6" sx={{ textTransform: 'uppercase' }}>
          {title}
        </Typography>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: description ? description : '' }}
        ></div>
      </Box>
      <Box className={styles.card_actions}>{/* <Button size="small">Подробнее</Button> */}</Box>
    </Box>
  );
}
