// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Carousel from 'react-spring-3d-carousel';
import { useState, useEffect } from 'react';
import { config } from 'react-spring';

interface BonusCarouselProps {
  width: string;
  height: string;
  cards: any;
  margin?: string;
  offset: number;
  showArrow: boolean;
}
const initState = {
  enableSwipe: true,
};

export default function BonusCarousel({
  width,
  height,
  cards,
  margin,
  offset,
  showArrow,
}: BonusCarouselProps) {
  const [state, setState] = useState(initState);
  const [offsetRadius, setOffsetRadius] = useState(2);
  const [showArrows, setShowArrows] = useState(false);
  const [goToSlide, setGoToSlide] = useState<number>(0);
  const [cardList, setCardList] = useState([]);

  const getTouches = (evt: { touches: any; originalEvent: { touches: any } }) => {
    return evt.touches || evt.originalEvent.touches;
  };

  const handleTouchStart = (evt) => {
    if (!state.enableSwipe) {
      return;
    }

    const firstTouch = getTouches(evt)[0];
    setState((state) => ({
      ...state,
      xDown: firstTouch.clientX,
      yDown: firstTouch.clientY,
    }));
  };

  const handleTouchMove = (evt) => {
    if (!state.enableSwipe || (!state.xDown && !state.yDown)) {
      return;
    }

    const xUp = evt.touches[0].clientX;
    const yUp = evt.touches[0].clientY;

    const xDiff = state.xDown - xUp;
    const yDiff = state.yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        setState((state) => ({
          ...state,
          xDown: null,
          yDown: null,
        }));
        setGoToSlide((slide) => slide + 1);
      } else {
        setState((state) => ({
          ...state,
          xDown: null,
          yDown: null,
        }));
        setGoToSlide((slide) => slide - 1);
      }
    }
  };

  useEffect(() => {
    const tableSlides = cards?.map((element: any, index: number) => {
      return { ...element, onClick: () => setGoToSlide(index) };
    });

    setCardList(tableSlides);
    setOffsetRadius(offset);
    setShowArrows(showArrow);
  }, [offset, showArrow, cards]);

  return (
    <div
      style={{ width: width, height: height, margin: margin }}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <Carousel
        slides={cardList}
        goToSlide={goToSlide}
        offsetRadius={offsetRadius}
        showNavigation={showArrows}
        animationConfig={config.gentle}
      />
    </div>
  );
}
