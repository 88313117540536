import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react';
import TermCard from 'components/termCard/termCard';
import { termsList } from 'components/termCard/mocked_data';
import styles from './bonusTerms.module.scss';

function BonusTerms() {
  const { t: footerT } = useTranslation('footer');
  const { t } = useTranslation('common');

  return (
    <Box className={styles.terms_area}>
      <Box className={styles.titles_info}>
        <Box>
          <Typography variant="h4">{footerT('bonusTermsAndConditions')}</Typography>
          <Typography component={'p'}>{t('termsSlogon')}</Typography>
        </Box>
      </Box>
      <Box className={styles.content}>
        {termsList.map((el, index) => {
          return (
            <Fragment key={index}>
              <TermCard {...el} index={index} />
            </Fragment>
          );
        })}
      </Box>
      <Box className={styles.footer}>
        <Box>
          <Typography component={'p'}>
            {' '}
            - Мы оставляем за собой право менять условия бонусных акций, правила и политику казино.
          </Typography>
          <Typography component={'p'}>
            {' '}
            - За прямое либо косвенное оскорбление работающего персонала полагается блокировка на 24
            часа, с блокировкой денежных средств на Вашем счету.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default BonusTerms;
