import { useState } from 'react';
import { useUser } from './useUser';
import { useStorage } from 'hooks/authHooks/useStorage';
import { useApiErrors } from 'hooks/errors/useApiErrors';
import { generateHeaders } from 'utils/utils';
import UserService from 'services/user.service';
import { useDispatch } from 'react-redux';
import { userNotificaionsSeen } from 'redux/actions/userDataAction';
import { useAuthContext } from 'context/auth/authContext';

export const useNotification = () => {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { userToken } = useStorage();
  const { removeUser } = useUser();
  const { errorHandler } = useApiErrors();

  const initHeaders = generateHeaders(userToken());

  const forUserNotificationSeen: any = async (body: any, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { status },
      } = await UserService.userNotificationsSeen(headers, body);

      return { status };
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { message, status },
          },
        } = err;
        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await forUserNotificationSeen(body, newHeader);
        } else {
          console.log('LOG____>', message);
          setError(message);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getUserNotification: any = async (query?: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await UserService.getUserNotifications(headers, query);
      if (status === 200) {
        setTotalItems(data.totalItems);
        setTotalPages(data.totalPages);

        return { data, status };
      }
    } catch (err: any) {
      if (err.response) {
        const {
          response: {
            data: { message, status },
          },
        } = err;
        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await getUserNotification(query, newHeader);
        } else {
          console.log('LOG____>', message);
          setError(message);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    forUserNotificationSeen,
    getUserNotification,
    totalItems,
    totalPages,
  };
};
