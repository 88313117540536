import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { routing } from 'constants/routing';
import { useTranslation } from 'react-i18next';
import styles from './notFound.module.scss';

export default function NotFound() {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const handleOnClick = () => {
    // TODO: navigate to the correct url considering is user logged in or no
    navigate(routing.home);
  };

  return (
    <div className={styles.container}>
      <h1>{t('pageNotFound')}</h1>
      <Button className={styles.btn} onClick={handleOnClick}>
        {t('goBack')}
      </Button>
    </div>
  );
}
