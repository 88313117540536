import cx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
// import { ChevronRightRounded } from '@mui/icons-material';
import styles from './bonusCard.module.scss';
import { useTranslation } from 'react-i18next';

interface BonusCardProps {
  image: string;
  title?: string;
  content: string;
}

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 30,
    boxShadow: 'none',
    transition: '0.3s cubic-bezier(0.47, 1.64, 0.41, 0.8)',
    '&:hover': {
      transform: 'scale(1.06)',
      boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.12)',
    },
  },
  content: {
    padding: 24,
  },
  cta: {
    marginTop: 24,
    textTransform: 'initial',
    fontSize: '0.8rem',
  },
}));

export default function BonusCard({ image, title, content }: BonusCardProps) {
  const classes = useStyles();
  const { t } = useTranslation('common');

  return (
    <Card className={cx(classes.root, styles.card)}>
      <CardMedia sx={{ width: '100%', height: '220px' }} image={image} />
      <CardContent className={cx(classes.content)}>
        <Typography className={styles.title} component={'h4'} textAlign={'center'}>
          {title}
        </Typography>
        <div
          className={styles.content_text}
          dangerouslySetInnerHTML={{ __html: content ? content : '' }}
        ></div>
        {/* <Button color={'primary'} fullWidth className={classes.cta}>
          {t('apply')} <ChevronRightRounded />
        </Button> */}
      </CardContent>
    </Card>
  );
}
