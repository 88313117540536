import { Box, CircularProgress, Pagination, Stack, Tab, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import DescriptionIcon from '@mui/icons-material/Description';
import viza from 'assets/payment/visa.png';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { ITabs } from 'components/modals/depositModal/depositModal';
import { useTransaction } from 'hooks/transactions/useTransaction';
import { useBalance } from 'hooks/user/useBalance';
import { useStyles } from 'components/pageLayout/category/category';
import styles from './history.module.scss';

enum HISTORY_ITEM {
  DEPOSIT = 'deposit',
  WITHDRAW = 'withdraw',
  BONUS = 'bonus',
  TRANSFER = 'transfer',
}

interface HistoryProps {
  historyTabMenu: ITabs[];
}

function renderTransfer(item: any, index: number, t: any): any {
  return (
    <Box key={index} className={styles.history_item}>
      <Typography>{t(`${item?.balance?.name}`)}</Typography>
      <Typography>{item?.oldBalance}</Typography>
      <Typography>{item?.newBalance}</Typography>
      <Typography> {`${moment(item.date).format('DD/MM/Y HH:mm:ss')}`} </Typography>
    </Box>
  );
}

function History({ historyTabMenu }: HistoryProps) {
  const { t: commonT } = useTranslation('common');
  const { t } = useTranslation('deposit');
  const [value, setValue] = useState<string>('0');
  const [historyList, setHistoryList] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [page, setPage] = useState(1);
  const classes = useStyles();

  const { isLoading, getAllDepositTransactions, getAllWithdrawTransactions } = useTransaction();
  const { isLoading: isLoadingBalancechanges, getUserBalanceChangesAll } = useBalance();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue + '');
    setPage(1);
    setTotalPages(null);
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    if (historyTabMenu.length) {
      const paramsQuery = `&page=${page}&pageSize=15`;
      let res: any;
      const fetchData = async (type: string) => {
        switch (type) {
          case HISTORY_ITEM.BONUS:
            res = await getAllDepositTransactions(paramsQuery + '&isBonus=true');
            break;
          case HISTORY_ITEM.TRANSFER:
            res = await getUserBalanceChangesAll(paramsQuery);
            break;
          case HISTORY_ITEM.DEPOSIT:
            res = await getAllDepositTransactions(paramsQuery + '&isBonus=false');
            break;
          case HISTORY_ITEM.WITHDRAW:
            res = await getAllWithdrawTransactions(paramsQuery);
            break;
          default:
            null;
        }
        if (res) {
          setHistoryList(res.data);
          setTotalPages(res.totalPages);
        }
      };

      fetchData(historyTabMenu[+value].name);
    }
  }, [historyTabMenu, value, page]);

  return (
    <Box className={styles.main_area}>
      <Box className={styles.main_title}>{t('history')}</Box>
      <Box className={styles.methods_block}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              {historyTabMenu.map(({ name }, index) => {
                return (
                  <Tab
                    key={index}
                    value={index + ''}
                    label={t(name)}
                    sx={{ alignItems: 'start' }}
                  />
                );
              })}
            </TabList>
          </Box>
          <TabPanel value={value}>
            <Box className={styles.history_block}>
              {isLoading ? (
                <Stack
                  sx={{ minHeight: '520px', color: 'grey.500', justifyContent: 'center' }}
                  spacing={2}
                  direction="row"
                >
                  <CircularProgress color="warning" />
                </Stack>
              ) : (
                <Box className={styles.history_block_lists}>
                  {historyList.length ? (
                    historyTabMenu[+value].name !== HISTORY_ITEM.TRANSFER ? (
                      historyList.map((item: any, index: number) => {
                        return (
                          <Box key={index} className={styles.history_item}>
                            <Box>
                              <img src={item.icon ?? viza} alt="cards" width={65} />
                            </Box>
                            <Box>
                              {item.amount} {item.currency}
                            </Box>
                            <Box> {`${moment(item.date).format('DD/MM/Y HH:mm:ss')}`} </Box>
                            <Box
                              //TODO: write css detector function
                              className={cx({
                                [styles.pending]: item.status === 'pending',
                                [styles.completed]: item.status === 'completed',
                                [styles.failed]: item.status === 'failed',
                                [styles.returned]: item.status === 'canceled_returned',
                                [styles.canceled]: item.status === 'canceled',
                              })}
                            >
                              {t(item.status)}
                            </Box>
                          </Box>
                        );
                      })
                    ) : (
                      <>
                        {isLoadingBalancechanges ? (
                          <Stack
                            sx={{ minHeight: '520px', color: 'grey.500', justifyContent: 'center' }}
                            spacing={2}
                            direction="row"
                          >
                            <CircularProgress color="warning" />
                          </Stack>
                        ) : (
                          <>
                            <Box className={styles.history_item_title}>
                              <Typography>Тип баланса </Typography>
                              <Typography>Старый Баланс</Typography>
                              <Typography>Новый Баланс</Typography>
                              <Typography>Дата</Typography>
                            </Box>
                            {historyList.map((item: any, index: number) => {
                              return renderTransfer(item, index, commonT);
                            })}
                          </>
                        )}
                      </>
                    )
                  ) : (
                    <Box className={styles.empty}>
                      <DescriptionIcon />
                      <Typography component="span">{t('emptyHistory')}</Typography>
                    </Box>
                  )}
                </Box>
              )}
              {(!isLoading || !isLoadingBalancechanges) && totalPages && totalPages > 1 ? (
                <Stack spacing={2} sx={{ m: 4 }}>
                  <Pagination
                    count={totalPages}
                    className={classes.ul}
                    hideNextButton
                    hidePrevButton
                    page={page}
                    onChange={handleChangePage}
                  />
                </Stack>
              ) : null}
            </Box>
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
}

export default History;
