import http2 from '../http2-common';

class BonusDataService {
  
  getUserBonusesData = (headers) => {
    return http2.get(`/users/bonuses/all`, { headers });
  };

  applyBonus = (headers, bonusId) => {
    return http2.put(`/users/bonuses/${bonusId}/apply`, {}, { headers });
  };

  cancelBonus = (headers, bonusId) => {
    return http2.put(`/users/bonuses/${bonusId}/cancel`, {}, { headers });
  };
}

const BonusService = new BonusDataService();

export default BonusService;
