import {
  Box,
  Button,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
} from '@mui/material';
import cx from 'classnames';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useTranslation } from 'react-i18next';
import { FIELD_NAME_SECURITY } from 'types/user';
import { ChangeEvent, useState } from 'react';
import { minLength } from 'utils/validations/validationTypes';
import styles from './security.module.scss';

const field = {
  [FIELD_NAME_SECURITY.OLD_PASSWORD]: true,
  [FIELD_NAME_SECURITY.NEW_PASSWORD]: true,
  [FIELD_NAME_SECURITY.CONFIRM_PASSWORD]: true,
};

const initialData = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

function SecurityTab() {
  const { t: profileT } = useTranslation('profile');
  const { t: validationT } = useTranslation('validation');
  const [formData, setFormData] = useState(initialData);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [validField, setValidField] = useState(field);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setValidField((validField) => ({
      ...validField,
      [FIELD_NAME_SECURITY.CONFIRM_PASSWORD]: formData.confirmPassword === formData.newPassword,
    }));
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, name: string) => {
    const { value } = e.target;
    setFormData((formData) => ({ ...formData, [name]: value }));
    setValidField((validField) => ({
      ...validField,
      [name]: minLength(7)(value),
    }));
  };
  const handleClickShowPassword = (name: string) => {
    name === FIELD_NAME_SECURITY.NEW_PASSWORD
      ? setShowNewPassword((show) => !show)
      : name === FIELD_NAME_SECURITY.OLD_PASSWORD
        ? setShowOldPassword((show) => !show)
        : setShowConfirmPassword((show) => !show);
  };

  return (
    <Box className={styles.main_area}>
      <Box className={styles.main_title}>{profileT('security')}</Box>
      <Box component="form" onSubmit={handleSubmit}>
        <Box className={styles.input_content}>
          <FormControl
            className={cx(styles.form_control, {
              [styles.is_error]: !validField[FIELD_NAME_SECURITY.OLD_PASSWORD],
            })}
          >
            <InputLabel shrink htmlFor="oldPassword-input">
              {validField[FIELD_NAME_SECURITY.OLD_PASSWORD] ? (
                profileT('oldPassword')
              ) : (
                <Typography sx={{ color: 'red', fontWeight: 600 }}>
                  {validationT('invalidOldPassword')}
                </Typography>
              )}
            </InputLabel>
            <Input
              className={styles.custom_input}
              placeholder={profileT('fillOldPassword')}
              fullWidth
              endAdornment={
                <InputAdornment position="end" sx={{ width: 30 }}>
                  <IconButton
                    onClick={() => handleClickShowPassword(FIELD_NAME_SECURITY.OLD_PASSWORD)}
                    edge="end"
                  >
                    {!showOldPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                  </IconButton>
                </InputAdornment>
              }
              type={showOldPassword ? 'text' : 'password'}
              value={formData.oldPassword}
              id="oldPassword-input"
              onChange={(e) => handleOnChange(e, FIELD_NAME_SECURITY.OLD_PASSWORD)}
              required
            />
          </FormControl>
          <FormControl
            className={cx(styles.form_control, {
              [styles.is_error]: !validField[FIELD_NAME_SECURITY.NEW_PASSWORD],
            })}
          >
            <InputLabel shrink htmlFor="nickname-input">
              {validField[FIELD_NAME_SECURITY.NEW_PASSWORD] ? (
                profileT('newPassword')
              ) : (
                <Typography>{validationT('minLength', { length: 6 })}</Typography>
              )}
            </InputLabel>
            <Input
              className={styles.custom_input}
              placeholder={profileT('fillNewPassword')}
              fullWidth
              type={showNewPassword ? 'text' : 'password'}
              required
              id="newPassword-input"
              onChange={(e) => handleOnChange(e, FIELD_NAME_SECURITY.NEW_PASSWORD)}
              endAdornment={
                <InputAdornment position="end" sx={{ width: 30 }}>
                  <IconButton
                    onClick={() => handleClickShowPassword(FIELD_NAME_SECURITY.NEW_PASSWORD)}
                    edge="end"
                  >
                    {!showNewPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl
            className={cx(styles.form_control, {
              [styles.is_error]: !validField[FIELD_NAME_SECURITY.CONFIRM_PASSWORD],
            })}
            sx={{ margin: 0 }}
          >
            <InputLabel shrink htmlFor="confirmPassword-input">
              {validField[FIELD_NAME_SECURITY.CONFIRM_PASSWORD] ? (
                profileT('confirmPassword')
              ) : (
                <Typography>{validationT('invalidConfirmPass')}</Typography>
              )}
            </InputLabel>
            <Input
              className={styles.custom_input}
              placeholder={profileT('fillConfirmPassword')}
              type={showConfirmPassword ? 'text' : 'password'}
              fullWidth
              required
              endAdornment={
                <InputAdornment position="end" sx={{ width: 30 }}>
                  <IconButton
                    onClick={() => handleClickShowPassword(FIELD_NAME_SECURITY.CONFIRM_PASSWORD)}
                    edge="end"
                  >
                    {!showConfirmPassword ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <VisibilityOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              id="confirmPassword-input"
              onChange={(e) => handleOnChange(e, FIELD_NAME_SECURITY.CONFIRM_PASSWORD)}
            />
          </FormControl>
         
            <Button
              fullWidth
              disabled={
                !validField[FIELD_NAME_SECURITY.NEW_PASSWORD] ||
                !validField[FIELD_NAME_SECURITY.OLD_PASSWORD] ||
                !validField[FIELD_NAME_SECURITY.CONFIRM_PASSWORD]
              }
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
            >
              {profileT('save')}
            </Button>
          
        </Box>
      </Box>
    </Box>
  );
}

export default SecurityTab;
