import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from './paymentFailed.module.scss';

function PaymentFailed() {
  const { t } = useTranslation('common');

  return <Box className={styles.content}>{t('paymentFaild')}</Box>;
}

export default PaymentFailed;
