import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Menu,
  Button,
  MenuItem,
  Tooltip,
  Skeleton,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { ReactElement, useEffect } from 'react';
import logoMobile from 'assets/logo/logo.webp';
import notification from 'assets/icons/notification.svg';
import cx from 'classnames';
import { ReactComponent as WheelIcon } from 'assets/icons/menuIcons/wheel.svg';
import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import BalanceCheck from 'components/balanceCheck/balanceCheck';
import { useTranslation } from 'react-i18next';
import { useGame } from 'hooks/playGames/useGame';
import { FIELD_NAME } from 'types/login';
import { useModalContext } from 'context/modal/modalContext';
import { FIELD_NAME_PROFILES } from 'types/user';
import RecentActorsOutlinedIcon from '@mui/icons-material/RecentActorsOutlined';
import HistoryIcon from '@mui/icons-material/History';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import MessageIcon from '@mui/icons-material/Message';
import InsightsIcon from '@mui/icons-material/Insights';
import { MenuMobile } from 'components/mobile/menu/menuMobile';
import { SearchMobile } from 'components/mobile/menu/searchMobile';
import { TABLET } from 'components/sections/topSection';
import { socket } from 'App';
import Login from 'components/login/login';
import Signup from 'components/signup/signup';
import CloseIcon from '@mui/icons-material/Close';
import Level from 'components/level/level';
import { useAuthContext } from 'context/auth/authContext';
import Notification from 'components/notification/notification';
import { routing } from 'constants/routing';
import { useDispatch, useSelector } from 'react-redux';
import { useNotification } from 'hooks/user/useNotifications';
import {
  getNotification,
  updateUserNotification,
  userNotificaionsSeen,
} from 'redux/actions/userDataAction';
import { useStorage } from 'hooks/authHooks/useStorage';
import { Telegram } from '@mui/icons-material';
import { mobileMenuItems } from 'constants/common';
import { ISiteSocial } from 'types/site';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import HomeIcon from '@mui/icons-material/Home';
import StreamIcon from '@mui/icons-material/Stream';
import BalanceIcon from '@mui/icons-material/Balance';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import styles from './navBar.module.scss';

/**TODO: bottom_menu_mobile write as component and use map() */

export const main_menu = [
  { id: '1', menu: 'lobby', link: '/', icon: <HomeIcon /> },
  { id: '8', menu: 'slots', link: '/slots', icon: <SportsBasketballIcon /> },
  { id: '2', menu: 'live', link: '/live', icon: <StreamIcon /> },
  { id: '3', menu: 'balance', link: '/balance', icon: <BalanceIcon /> },
  { id: '6', menu: 'bonuses', link: '/bonuses', icon: <WorkspacePremiumIcon /> },
  { id: '7', menu: 'statistics', link: '/statistics', icon: <QueryStatsIcon /> },
  { id: '8', menu: 'wheel', link: '/wheel', icon: <WheelIcon /> },
];

const settings = [
  { id: '1', item: 'profile', modalType: 'profile', icon: RecentActorsOutlinedIcon },
  { id: '2', item: 'balanceTransfer', modalType: 'balance', icon: MonetizationOnOutlinedIcon },
  { id: '2', item: 'withDraw', modalType: 'withDraw', icon: CreditScoreIcon },
  { id: '3', item: 'userLevels', modalType: 'levels', icon: RocketLaunchIcon },
  { id: '4', item: 'claims', modalType: 'claims', icon: MessageIcon },
  { id: '5', item: 'gameHistory', modalType: 'history', icon: HistoryIcon },
  { id: '6', item: 'notification', modalType: 'notification', icon: NotificationsNoneOutlinedIcon },
  { id: '7', item: 'logOut', modalType: 'logout', icon: LogoutIcon },
];

interface ResponsiveNavBarProps {
  onHandleClick: (value: string) => void;
}

export const modalTypesObj: { [x: string]: string } = {
  logout: FIELD_NAME.LOGOUT,
  profile: FIELD_NAME_PROFILES.PROFILE,
  history: FIELD_NAME_PROFILES.HISTORY,
  deposit: FIELD_NAME_PROFILES.DEPOSIT,
  withDraw: FIELD_NAME_PROFILES.DEPOSIT,
  levels: FIELD_NAME_PROFILES.LEVELS,
  balance: FIELD_NAME_PROFILES.DEPOSIT,
  notification: FIELD_NAME_PROFILES.NOTIFICATION,
};

export const activeTab: { [x: string]: string } = {
  bonuses: '3',
  deposit: '1',
  balance: '0',
  withDraw: '2',
};

function ResponsiveNavBar({ onHandleClick }: ResponsiveNavBarProps) {
  const location = useLocation();
  const { t } = useTranslation('menu');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t: navBarProfileT } = useTranslation('navBarProfile');
  // const { getSessionWhenLogout } = useGame();
  const { t: loginAndSignupT } = useTranslation('loginAndSignup');
  const { forUserNotificationSeen, getUserNotification } = useNotification();
  const { state } = useModalContext();
  const { user } = useAuthContext();
  const userData = useSelector<any>((state) => state.userData) as any;
  const { updateData, readUserData, readStorageData } = useStorage();
  const { socials } = useSelector<any>((state) => state.siteConfigs) as { socials: ISiteSocial[] };
  const { openModalWithData } = useModalContext();
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [anchorElNoti, setAnchorElNoti] = useState<null | HTMLElement>(null);
  const [activeItem, setActiveItem] = useState<string>(
    (readStorageData('activeMenuTab') as string) ?? 'lobby',
  );
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>, component: string) => {
    setSelectedItem(component);
    if (component !== mobileMenuItems.LOBBY) {
      if (component === mobileMenuItems.STATISTICS) {
        navigate(`${routing.statistics}`);
      } else {
        setAnchorElNav(event.currentTarget);
        setActiveItem(component);
      }
    } else {
      navigate(`${routing.home}`);
    }

    if (component === mobileMenuItems.TELEGRAM) {
      setAnchorElNav(null);
      setActiveItem('');
    }
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    setActiveItem('');
    setIsOpen(false);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    setActiveItem('');
    setIsOpen(false);
  };

  const handleCloseNotiMenu = async () => {
    setAnchorElNoti(null);
    setIsOpen(false);

    const notificationArray: any = userData.notifications?.lastNotifications;

    const body: any = {
      notifications: [] as any,
    };

    notificationArray.map((elm: any, i: number) =>
      i < 5 ? body.notifications.push(elm.id as never) : null,
    );

    const { status } = await forUserNotificationSeen(body);

    if (status === 200) {
      dispatch(userNotificaionsSeen(body.notifications));
      const user = readUserData();

      const notificationCount = user?.user?.notifications?.count;
      const lastNotifications = user?.user?.notifications?.lastNotifications;

      updateData({
        notifications: {
          count: `${Number(notificationCount) - body.notifications.length}`,
          lastNotifications: lastNotifications.filter(
            (elm: any) => !body.notifications.includes(elm.id),
          ),
        },
      });
    }
  };

  const handleItemClick = (type: string) => {
    if (type === FIELD_NAME.LOGOUT) {
      handleLogOut();
    } else {
      openModalWithData(modalTypesObj[type], { activeTab: activeTab[type] });
    }
    setAnchorElUser(null);
    setActiveItem('');
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
    setIsOpen(false);
  };

  const handleLogOut = () => {
    /**TODO: discuss this comments then */

    // const data = await getSessionWhenLogout();
    openModalWithData(FIELD_NAME.LOGOUT, {
      // gameId: data?.id ?? null,
      style: { minWidth: '300px', padding: '25px', minHeight: 'unset' },
    });
  };

  const handleOnOpenDeposit = () => {
    openModalWithData(modalTypesObj['deposit'], { activeTab: activeTab['deposit'] });
  };

  const handleOpenModalNoti = () => {
    openModalWithData(modalTypesObj['notification'], null);
    setAnchorElNoti(null);
    setIsOpen(false);
  };

  const openNotification = async (event: React.MouseEvent<HTMLElement>) => {
    const userInfo = readUserData();
    const notificationCount = +userInfo?.user?.notifications?.count;
    const lastNotifications = userInfo?.user?.notifications?.lastNotifications;

    if (
      (notificationCount && !Number(lastNotifications.length)) ||
      (notificationCount > 5 && Number(lastNotifications.length) < 5)
    ) {
      setAnchorElNoti(event.currentTarget);
      setIsOpen(false);
      const query = '?isViewed=false';
      const { data, status } = await getUserNotification(query);
      if (status === 200) {
        const newData = {
          notifications: {
            count: `${data.totalItems}`,
            lastNotifications: data.userNotifications,
          },
        };

        dispatch(updateUserNotification(newData));
        updateData(newData);
      }
    } else if (!notificationCount) {
      openModalWithData(modalTypesObj['notification'], null);
      setAnchorElNoti(null);
      setIsOpen(false);
    } else {
      setAnchorElNoti(event.currentTarget);
      setIsOpen(false);
    }
  };

  const handleOpenLoginIn = () => {
    if (window.innerWidth <= TABLET) {
      setActiveItem('login');
      setIsOpen(true);
    } else {
      openModalWithData(FIELD_NAME.LOGIN, null);
    }
  };

  const handleOpenSignIn = () => {
    if (window.innerWidth <= TABLET) {
      setActiveItem('signup');
      setIsOpen(true);
    } else {
      openModalWithData(FIELD_NAME.SIGN_UP, null);
    }
  };

  const handleOnSwitchComponent = (name: string) => {
    setActiveItem(name);
  };

  const handleWindowResizing = () => {
    setWindowWidth(window.innerWidth);
  };

  const handleOnClick = (event: React.MouseEvent<HTMLElement>, name: string, link: string) => {
    event.preventDefault();
    setSelectedItem(name);
    if (name === 'balance' || name === 'wheel') {
      if (!user) {
        openModalWithData(
          FIELD_NAME.LOGIN,
          name === 'wheel'
            ? {
                redirect: routing.wheel,
              }
            : null,
        );
      } else if (name === 'balance') {
        openModalWithData(FIELD_NAME_PROFILES.DEPOSIT, null);
      } else {
        navigate(link);
      }
    } else {
      navigate(link);
    }
  };

  useEffect(() => {
    const data = location.pathname.split('/');
    if (!state.currentModal) {
      setSelectedItem(data[1] === '' ? 'lobby' : data[1]);
    }
  }, [state, location]);

  useEffect(() => {
    socket.on('userNotification', (data) => {
      const user = readUserData();

      const notificationCount = user?.user?.notifications?.count;
      const lastNotifications = user?.user?.notifications?.lastNotifications;

      dispatch(getNotification(data));
      updateData({
        notifications: {
          count: `${Number(notificationCount) + 1}`,
          lastNotifications: [data, ...lastNotifications],
        },
      });
    });

    window.addEventListener('resize', handleWindowResizing);

    return () => {
      window.removeEventListener('resize', handleWindowResizing);
    };
  }, []);

  const childComponent: { [x: string]: ReactElement } = {
    menu: <MenuMobile onCloseNavMenu={handleCloseNavMenu} user={user} />,
    search: <SearchMobile onCloseNavMenu={handleCloseNavMenu} />,
    login: (
      <Login onClose={handleCloseNavMenu} onSwitchComponent={handleOnSwitchComponent} isMobile />
    ),
    signup: (
      <Signup onClose={handleCloseNavMenu} onSwitchComponent={handleOnSwitchComponent} isMobile />
    ),
  };

  return (
    <>
      <AppBar
        sx={{
          background: '#0d131c',
          padding: '6px 0px!important',
          width: '100%',
          boxShadow: 'none',
          margin: ' 0 auto',
        }}
        position="fixed"
      >
        <Container maxWidth={false} className={styles.container}>
          <Toolbar className={styles.toolbar} disableGutters>
            <Box
              component="a"
              href="/"
              sx={{
                margin: { xs: '0 2px' },
              }}
            >
              <img className={styles.img} src={logoMobile} alt="logo" width={76} />
            </Box>
            <Box
              sx={{ flexGrow: 1, display: { xs: 'none', sm: 'none', md: 'flex' } }}
              className={styles.navBar_menu_items}
            >
              {main_menu.map(({ menu, link, icon }, index) => {
                return (
                  <Link
                    className={cx(styles.links, {
                      [styles.active]: menu === selectedItem,
                    })}
                    to={link}
                    key={index}
                    onClick={(e) => handleOnClick(e, menu, link)}
                  >
                    {icon}
                    <Typography textAlign="center">{t(`${menu}`)}</Typography>
                  </Link>
                );
              })}
            </Box>

            <Box sx={{ flexGrow: 0 }} className={styles.right_side}>
              {!user ? (
                <Box sx={{ display: 'flex' }}>
                  <Box>
                    <Button onClick={handleOpenLoginIn} className={styles.login_btn}>
                      {loginAndSignupT('login')}
                    </Button>
                    <Button onClick={handleOpenSignIn} className={styles.sign_btn}>
                      {loginAndSignupT('signup')}
                    </Button>
                  </Box>
                </Box>
              ) : (
                <>
                  <Box sx={{ display: 'flex' }}>
                    <BalanceCheck />
                    {!userData?.balance?.length ? (
                      <Skeleton variant="rounded" sx={{ borderRadius: '16px' }}>
                        <Box className={styles.deposit}>
                          <Button> {t('deposit')}</Button>
                        </Box>
                      </Skeleton>
                    ) : (
                      <Box className={styles.deposit}>
                        <Button onClick={handleOnOpenDeposit}>{t('deposit')}</Button>
                      </Box>
                    )}

                    <Box>
                      <IconButton
                        sx={{ p: 0, flexGrow: 1, ml: { xs: 0.5, md: 1.5 }, mt: 0.5 }}
                        onClick={openNotification}
                      >
                        <img
                          className={styles.notification}
                          src={notification}
                          alt="notificationIcon"
                          width={28}
                          height={28}
                          style={{ position: 'relative' }}
                        />
                        {userData?.notifications?.count != 0 && windowWidth >= 430 ? (
                          <Box className={styles.has_notifications}>
                            <Typography component={'span'}>
                              {userData?.notifications?.count}
                            </Typography>
                          </Box>
                        ) : null}
                      </IconButton>
                    </Box>

                    <Menu
                      sx={{ mt: '45px', pt: 0 }}
                      id="menu-notification"
                      anchorEl={anchorElNoti}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(anchorElNoti)}
                      onClose={handleCloseNotiMenu}
                    >
                      <Notification
                        notificationList={userData?.notifications?.lastNotifications}
                        classNames={styles.navbar_noti}
                        wrapperClassName={styles.navbar_noti_wrapper}
                        onShowMoreExternal={handleOpenModalNoti}
                      />
                    </Menu>
                    <IconButton sx={{ p: 0, flexGrow: 1, ml: { xs: 0.5, md: 1.5 } }}>
                      {user ? (
                        <>
                          {!userData?.balance?.length ? (
                            <Skeleton variant="rounded" sx={{ borderRadius: '16px' }}>
                              <Tooltip title="Open settings">
                                <IconButton sx={{ p: 0 }} className={styles.avatar}>
                                  <Typography textAlign="center">
                                    {userData?.login?.charAt(0)}
                                  </Typography>
                                </IconButton>
                              </Tooltip>
                            </Skeleton>
                          ) : (
                            <Tooltip title="Open settings">
                              <IconButton
                                onClick={handleOpenUserMenu}
                                sx={{ p: 0 }}
                                className={styles.avatar}
                              >
                                <Typography textAlign="center">
                                  {userData?.login?.charAt(0)}
                                </Typography>
                              </IconButton>
                            </Tooltip>
                          )}
                          <Menu
                            sx={{ mt: '45px', pt: 0 }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                          >
                            <Level variant="buffer" />
                            {settings.map(({ id, item, modalType, icon }) => (
                              <MenuItem key={id} onClick={() => handleItemClick(modalType)}>
                                <Box className={styles.li_item}>
                                  <Box className={styles.icon}>
                                    {userData?.notifications?.count != 0 &&
                                    windowWidth <= 430 &&
                                    item === 'notification' ? (
                                      <Box className={styles.has_notifications_for_mobile}>
                                        <Typography component={'span'}>
                                          {userData?.notifications?.count}
                                        </Typography>
                                      </Box>
                                    ) : null}
                                    {React.createElement(icon)}
                                  </Box>

                                  <Typography textAlign="center">{navBarProfileT(item)}</Typography>
                                </Box>
                              </MenuItem>
                            ))}
                          </Menu>
                        </>
                      ) : null}
                    </IconButton>
                  </Box>
                </>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'flex', md: 'none' } }}>
        <Box className={styles.bottom_menu_mobile}>
          <Box onClick={(e) => handleOpenNavMenu(e, 'lobby')}>
            <IconButton
              size="large"
              aria-label="lobby"
              aria-controls="menu-lobby"
              disableRipple
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
              className={cx(styles.links, { [styles.active]: 'lobby' === selectedItem })}
            >
              <HomeOutlinedIcon />
              <Typography className={styles.title} textAlign="center">
                {t('lobby')}
              </Typography>
            </IconButton>
          </Box>
          <Box>
            <IconButton
              size="large"
              aria-label="statistics"
              aria-controls="menu-statistics"
              disableRipple
              onClick={(e) => handleOpenNavMenu(e, 'statistics')}
              color="inherit"
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
              className={cx(styles.links, { [styles.active]: selectedItem === 'statistics' })}
            >
              <InsightsIcon />
              <Typography className={styles.title} textAlign="center">
                {t('statistics')}
              </Typography>
            </IconButton>
          </Box>

          <Box>
            <IconButton
              size="large"
              disableRipple
              aria-label="search"
              aria-controls="menu-search"
              onClick={(e) => handleOpenNavMenu(e, 'search')}
              color="inherit"
              sx={{ display: 'flex', flexDirection: 'column' }}
              className={cx(styles.links, { [styles.active]: activeItem === 'search' })}
            >
              <SearchIcon />
              <Typography className={styles.title} textAlign="center">
                {t('search')}
              </Typography>
            </IconButton>
          </Box>
          <Box>
            <IconButton
              size="large"
              disableRipple
              aria-label="telegram"
              aria-controls="menu-telegram"
              color="inherit"
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
              className={cx(styles.links, { [styles.active]: activeItem === 'telegram' })}
            >
              <Telegram />
              <Link
                to={socials?.filter((el) => el.id === 4)?.[0]?.url ?? ''}
                className={styles.title}
                rel="noopener"
                target="_blank"
              >
                {t('telegram')}
              </Link>
            </IconButton>
          </Box>
          <Box>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              disableRipple
              onClick={(e) => handleOpenNavMenu(e, 'menu')}
              color="inherit"
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
              className={cx(styles.links, { [styles.active]: activeItem === 'menu' })}
            >
              <MenuIcon />
              <Typography className={styles.title} textAlign="center">
                {t('menu')}
              </Typography>
            </IconButton>
          </Box>
        </Box>
        <Menu
          id="menu"
          slotProps={{
            paper: {
              sx: {
                top: '0!important',
                width: '100vw',
                height: 'calc(100% - 72px)',
                maxHeight: 'calc(100% - 72px)',
                maxWidth: '100vw',
                left: '0!important',
                padding: '24px',
                boxShadow: 'none',
              },
            },
          }}
          anchorEl={anchorElNav}
          keepMounted
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={Boolean(anchorElNav) || isOpen}
          onClose={handleCloseNavMenu}
          sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'flex', md: 'none' } }}
        >
          <IconButton
            size="large"
            onClick={handleCloseNavMenu}
            color="inherit"
            sx={{ position: 'absolute', right: 0, p: 0 }}
          >
            <CloseIcon />
          </IconButton>

          {childComponent[activeItem]}
        </Menu>
      </Box>
    </>
  );
}
export default ResponsiveNavBar;
