import { Dialog, Alert } from '@mui/material';
import { ReactNode } from 'react';

interface IDialogAlertProps {
  contentStyle?: any;
  content?: string | ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

const DialogAlert = ({ content, isOpen, onClose }: IDialogAlertProps) => {
  return (
    <Dialog open={isOpen} onClick={onClose}>
      <Alert variant="filled" severity="success">
        {content}
      </Alert>
    </Dialog>
  );
};

export default DialogAlert;
