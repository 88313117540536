import http from '../http-common';
import { SITE_ID } from 'services/site.service';


class AboutUsService {
    getSiteAboutUs = (headers) => http.get(`/site/${SITE_ID}/about`, { headers });
}

const SiteAboutUsService = new AboutUsService()

export default SiteAboutUsService