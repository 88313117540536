import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import logoMobile from 'assets/logo/logo.webp';

interface ILoader {
  isOpen: boolean;
}

export default function LoaderWithLogo({ isOpen }: ILoader) {
  return (
    <div>
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          span: { width: '90px!important', height: '90px!important', opacity: '0.5' },
        }}
        open={isOpen}
      >
        <CircularProgress color="warning" />
        <img src={logoMobile} width={70} style={{ position: 'absolute' }} alt="logo"/>
      </Backdrop>
    </div>
  );
}
