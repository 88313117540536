import React from 'react';
import { Box, Typography, Button, IconButton, MenuItem } from '@mui/material';
import LanguageMenu from 'components/languageMenu/languageMenu';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import eiUser from 'assets/icons/eiUser.svg';
import { useAuthentication } from 'hooks/authHooks/useAuthentication';
import { ReactComponent as Deposit } from 'assets/icons/menuIcons/deposit.svg';
import { useState } from 'react';
import Signup from 'components/signup/signup';
import { FIELD_NAME } from 'types/login';
import { activeTab, main_menu } from 'components/navBar/navBar';
import Login from 'components/login/login';
import { Link, useNavigate } from 'react-router-dom';
import { useModalContext } from 'context/modal/modalContext';
import { routing } from 'constants/routing';
import { FIELD_NAME_PROFILES } from 'types/user';
import styles from './menuMobile.module.scss';

export const menu_mobile_secondary = [
  { id: '8', menu: 'deposit', link: '/deposit', icon: <Deposit /> },
];

interface MenuMobileProps {
  onCloseNavMenu: () => void;
  user: any;
}

export const MenuMobile = ({ onCloseNavMenu, user }: MenuMobileProps) => {
  const { t } = useTranslation('menu');
  const { t: loginAndSignupT } = useTranslation('loginAndSignup');
  const { hasToken } = useAuthentication();
  const { openModalWithData } = useModalContext();
  const [show, setShow] = useState({ login: false, signup: false });
  const navigate = useNavigate();
  const handleOnOpen = (name: string) => {
    setShow((data) => ({ ...data, [name]: true }));
  };

  const handleOnClick = (event: React.MouseEvent<HTMLElement>, type: string, link: string) => {
    event.preventDefault();
    if (type === 'balance' || type === 'wheel' || type == 'deposit') {
      if (!user) {
        openModalWithData(
          FIELD_NAME.LOGIN,
          type === 'wheel'
            ? {
                redirect: routing.wheel,
              }
            : null,
        );
      } else if (type === 'balance') {
        openModalWithData(FIELD_NAME_PROFILES.DEPOSIT, null);
      } else if (type === 'deposit') {
        openModalWithData(FIELD_NAME_PROFILES.DEPOSIT, { activeTab: activeTab['deposit'] });
      } else {
        navigate(link);
      }
    } else {
      navigate(link);
    }
  };

  const handleOnSwitchComponent = (name: string) => {
    setShow({ login: false, signup: false, [name]: true });
  };

  const handleOnClose = () => {
    setShow((data) => ({ ...data, login: false, signup: false }));
  };

  return (
    <>
      {!(show.login || show.signup) ? (
        <>
          <Box className={styles.top_mobile}>
            <Typography className={styles.anchor_title} textAlign="left" sx={{ mb: 4 }}>
              {t('menu')}
            </Typography>
            <Box className={cx(styles.action_btn, { [styles.hasToken]: user })}>
              {!user ? (
                <>
                  <Button
                    onClick={() => handleOnOpen(FIELD_NAME.LOGIN)}
                    className={styles.login_btn}
                  >
                    {loginAndSignupT('login')}
                  </Button>
                  <Button
                    onClick={() => handleOnOpen(FIELD_NAME.SIGN_UP)}
                    className={styles.sign_btn}
                  >
                    {loginAndSignupT('signup')}
                  </Button>
                </>
              ) : (
                <>
                  <Typography className={styles.anchor_title} textAlign="left">
                    {user?.name}
                  </Typography>
                  <IconButton sx={{ p: 0, ml: 3 }}>
                    <img src={eiUser} alt="userIcon" width={28} />
                  </IconButton>
                </>
              )}
            </Box>
          </Box>
          <Box>
            {main_menu.map(({ menu, icon, link }, index) => {
              if (menu !== 'favorites' || hasToken) {
                return (
                  <MenuItem className={cx(styles.menu_mobile)} key={index} onClick={onCloseNavMenu}>
                    <Link
                      to={link}
                      style={{ display: 'flex', alignItems: 'center' }}
                      onClick={(e) => handleOnClick(e, menu, link)}
                    >
                      {icon}
                      <Typography sx={{ ml: 2 }} textAlign="center">
                        {t(`${menu}`)}
                      </Typography>
                    </Link>
                  </MenuItem>
                );
              }
            })}
          </Box>
          <Box>
            {menu_mobile_secondary.map(({ menu, icon, link }, index) => (
              <MenuItem className={cx(styles.menu_mobile)} key={index} onClick={onCloseNavMenu}>
                <Link
                  to={link}
                  style={{ display: 'flex', alignItems: 'center' }}
                  onClick={(e) => handleOnClick(e, menu, link)}
                >
                  {icon}
                  <Typography sx={{ ml: 2 }} textAlign="center">
                    {t(`${menu}`)}
                  </Typography>
                </Link>
              </MenuItem>
            ))}
          </Box>
          <Box>
            <LanguageMenu isMobile />
          </Box>
        </>
      ) : show.login ? (
        <Login onClose={handleOnClose} onSwitchComponent={handleOnSwitchComponent} isMobile />
      ) : (
        <Signup onClose={handleOnClose} onSwitchComponent={handleOnSwitchComponent} isMobile />
      )}
    </>
  );
};
