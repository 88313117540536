import img1 from 'assets/faqs/1.webp';
import img2 from 'assets/faqs/2.webp';
import img7 from 'assets/faqs/7.webp';
import img3 from 'assets/faqs/3.webp';
import img4 from 'assets/faqs/4.webp';
import img5 from 'assets/faqs/5.webp';
import img6 from 'assets/faqs/6.webp';

export const faqs = [
  {
    title: 'Как зарегистрироваться в казино?',
    img: img1,
    description: `На странице регистрации, которая находится на правом углу
     сайта Вам нужно будет заполнить форму с личной информацией.  
<ol>
<li>Логин— Введите логин (имя пользователя), который Вы будете 
использовать для входа в свою учётную запись. 
Выберите логин, который легко запомнить, но он должен быть уникальным для казино. 
Например, можно использовать комбинацию вашего имени и цифр.</li>
<li>Создайте пароль для своей учётной записи.
Он должен быть сложным и безопасным, чтобы защитить вашу 
информацию. Используйте комбинацию больших и малых букв, цифр и специальных символов. 
Он  должен быть длиной не менее 8 символов. 
Не используйте очевидные пароли, такие как ваше имя или дата рождения.</li>
<li>Электронная почта - укажите действующий адрес электронной почты.</li>
<li>Телефон - Укажите номер телефона, к которому у вас есть доступ. </li>
<li>Валюта - Выберите валюту, которая будет удобна для вас, учитывая,
 что она будет использоваться для всех депозитов и выводов.  </li>
 <li>Прочитайте условия на сайте только потом дайте свое согласие о защите данных.</li>
</ol>`,
  },
  {
    title: 'Что делать если я забыл пароль?',
    img: img7,
    description: `  
<ol>
<li>Перейдите на сайт казино: Зайдите на официальный сайт казино, где у вас был аккаунт.</li>
<li>Найдите раздел "Забыли пароль?": это ссылка, которая появляется на экране входа в личный кабинет.</li>
<li>Вы можете восстановить пароль через почту либо через номера телефона.</li>
<li>Если выбрали восстановить через почту,
введите адрес электронной почты, который вы использовали при регистрации. 
Казино отправит вам инструкцию по восстановлению доступа.</li>
<li>Если выбрали восстановить через телефон, после ввода номера телефона казино
отправит вам SMS-сообщение с кодом подтверждения,
либо Вам поступит звонок и нужно будет ввести последние цифры номера.</li>
<li>Сбросьте пароль:  Установите новый пароль. Убедитесь,
что новый пароль надежный и состоит из букв, цифр и специальных символов.</li>
</ol>
 `,
  },

  {
    title: 'Как пополнить счёт?',
    img: img2,
    description: ` После регистрации Вы можете пополнить свой игровой счёт с помощью одного из доступных методов.
<ol>
<li>Войдите в свой аккаунт: Перейдите на сайт казино и авторизуйтесь, используя свой логин и пароль.</li>
<li>Перейдите в раздел Депозит: Выберите метод пополнений
<ul>
<li>Банковские карты 
</li>
<li>
Электронные кошельки 
</li>
<li>
Криптовалюты 
</li>
<li>
 Платежные системы 
</li>
<li>Банковские переводы 
</li>
<li>Оплата через оператора
</li>
</ul>
</li>
<li> Введите сумму пополнения </li>
<li> Подтвердите операцию</li>
</ol>
Если вам нужно внести депозит через оператора, следуйте этим шагам:
<ol>
<li> Выбор метода оплаты через оператора: На сайте казино или в
 приложении выберите опцию внесения депозита через оператора. 
Обычно эта опция подразумевает прямую работу с человеком, который
 поможет вам совершить перевод.
</li>
<li>Перенаправление в Telegram: После выбора метода, вас может 
перенаправить на Telegram сотрудника казино. 
</li>
<li>
Укажите свой логин: В сообщении сотруднику важно указать ваш логин. 
</li>
<li>
Укажите сумму депозита: Напишите сумму, которую вы хотите внести на свой счет. Убедитесь,
что сумма правильно указана и соответствует требуемой для перевода.
</li>
<li>
Отправьте чек перевода: После того как вы отправили деньги через выбранный способ,
отправьте чек или подтверждение о платеже сотруднику.
</li>
<li>
Ожидание подтверждения: После того как сотрудник получит все необходимые данные,
он проверит платеж и, если все в порядке, пополнит ваш счет в казино. 
Обычно это занимает от нескольких минут до нескольких часов, в зависимости от процесса проверки.
</ol>
 `,
  },
  {
    title: 'Как вывести деньги?',
    img: img3,
    description: `Чтобы вывести деньги из казино, выполните следующие шаги:
<ol>
<li> Войдите в свой аккаунт: Перейдите на сайт казино и авторизуйтесь, используя свой логин и пароль.</li>
<li>В разделе депозит, которая находится на правом углу сайта, выберите кнопку "Вывод».</li>
<li>Нажав на кнопку "Вывод", вы попадете на страницу, где будет представлен 
список доступных способов вывода средств.</li>
<li>Выберите тот метод, который вам удобен для вывода средств.</li>
<li>Как только все данные будут введены и операция подтверждена, 
система казино начнет обработку вашего вывода. 
Обычно на экране появляется уведомление о том, 
что запрос на вывод был успешно отправлен.</li>
</ol>
 `,
  },
  {
    title: 'Что такое бонусы и как их получить?',
    img: img4,
    description: `Бонусы в онлайн-казино - это дополнительные деньги,
     которые казино предоставляет своим игрокам. Бонусы могут быть предложены как новым,
     так и уже зарегистрированным пользователям,
<ol>
<li>Промокод для новых клиентов -  Это одноразовый код, по которому игроку начисляются 
бездепозитные денежные средства. Дается только по приглашению. </li>
<li> Колесо фортуны - Активные игроки в Магнит-Х получают уникальную возможность 
каждый день выигрывать до 1000 рублей! 
Для того, чтобы получить доступ к колесу нужно достичь уровня "Мастер" или выше.  </li>
<li>КЕШ БЕК - Каждый день в 09:00 по московскому времени сайт 
Магнит-Х начисляет кэшбэк в размере 3% - 8% от проигранных средств в 
зависимости от уровня клиента. Игрок имеет право 
на снятие кэшбэка без удержания комиссии. Начисление кэшбэка происходит автоматически. </li>
<li>Телеграмм каннал - Подписывайтесь на Telegram канал. 
На канале часто разыгрываются бонусы и проводятся акции, также Вы получите все новости касаемые
 с сайтом. Игроки Магнит-Х могут выиграть промокоды в Телеграмме канале. </li>
<li>Соц. сети - Мы предлагаем бонусы за подписку на наши каналы в социальных сетях, включая Telegram, ВК каналы.
Размер бонуса зависит от 
уровня клиента и может варьироваться в зависимости от активности на этих платформах. </li>
<li>Мульти-промокод - Мульти-промокод можно получить исключительно через участие в розыгрыше, который
 регулярно проводится в нашем Telegram-канале. </li>
<li>Почта - Для того чтобы получать ежемесячный бонус в размере 100 рублей, отправьте ваш логин на электронную почту . 
В течение 24 часов наша администрация проверит запрос и зачислит бонус на ваш аккаунт. </li>
<li>Фриспины - Это бонус, который дает игрокам возможность бесплатно вращать барабаны в слотах,
при этом сохраняя шанс на реальный выигрыш. Фриспины предоставляются только активным игрокам.</li>
<li>Бонус на день рождения  - Бонус предоставляется постоянным клиентам, успешно прошедшим верификацию аккаунта, 
и его сумма зависит от вашего текущего уровня в системе.</li>
</ol>
     `,
  },

  {
    title: 'Что такое верификация аккаунта и как ее пройти?',
    img: img5,
    description: ` 
<ol>
<li>Войдите в свой аккаунт - Перейдите на сайт казино и авторизуйтесь,
 используя свой логин и пароль. Убедитесь, что вы вводите правильные данные.</li>
<li> Перейдите в свой профиль: Найдите раздел верификации.</li>
<li> Подтвердите номер телефона - На номер телефона казино отправит вам SMS-сообщение с кодом подтверждения. </li>
<li> Подтвердите адрес электронной почты - На вашу почту будет отправлено письмо с кодом. Введите код в поле, 
чтобы подтвердить, что вы обладаете указанным адресом.</li>
<li> Загрузите документ, удостоверяющий личность  - Паспорт (страницы с фото и подписью),
Водительские права, Идентификационная карта.</li>
<li>Загрузите фото с документом - Документ должен быть четким и читаемым, чтобы сотрудник казино мог подтвердить ваши данные.
На фото должно быть видно ваше лицо и документ. </li>
<li>Ожидание подтверждения - После загрузки всех необходимых документов потребуется 
некоторое время для их проверки. Обычно этот процесс занимает от нескольких 
часов до нескольких дней.
В этот период вы можете не иметь доступа к некоторым функциям аккаунта 
(например, выводу средств).</li>
</ol>`,
  },
  {
    title: 'Как узнать свой уровень?',
    img: img6,
    description: `Уровни в казино — это система, в которой игроки могут 
    зарабатывать  лояльности или победные очки, в зависимости от их активности 
    (величины ставок, частоты игры и других факторов). Эти баллы накопляются, и 
    по мере того как игрок достигает определённых порогов, его уровень повышается. 
    С каждым новым уровнем игрок получает дополнительные 
    привилегии, бонусы и возможности. В нашем казино существуют 6 уровней
    <ol>
<li> Основной</li>
<li> Мастер</li>
<li> Профи</li>
<li> Премиум</li>
<li> VIP</li>
<li> Элит</li>
</ol>
Чтобы узнать свой текущий уровень, вам нужно:
<ol>
<li> Зайти в свой аккаунт на сайте или в приложении казино.</li>
<li> Перейти в свой профиль, "Уровни пользователя" Там будет указано, на каком уровне вы находитесь.</li>
<li> Также будет показано количество баллов лояльности, которые нужно набрать, чтобы перейти на следующий уровень.</li>
</ol>
    `,
  },
];
