import { Modal, Box } from '@mui/material';
import { IconClose } from 'components/closeButton/closeButton';
import { ReactElement } from 'react';
import { LoginModal } from './login/loginModal';
import color from 'styles/colors.module.scss';
import { SignupModal } from './signup/signupModal';
import { GameSession } from './gameSession/gameSession';
import { ModalState } from 'context/modal/modalContext';
import { Logout } from './logout/logout';
import Profile from 'components/modals/profileModal/profileModal';
import { ChooseBalance } from './chooseBalance/chooseBalance';
import DepositModal from './depositModal/depositModal';
import GameHistoryModal from './gameHistoryModal/gameHistoryModal';
import NotificationModal from './notification/notificationModal';
import LevelsModal from './levels/levelsModal';
import styles from './mainModal.module.scss';

interface MainModalProps {
  openModal: boolean;
  disableBackdropClick?: boolean;
  handleClose: () => void;
  modalType: string;
  state: ModalState;
}

export const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  maxHeight: 'calc(100vh - 10px)',
  height: 'inherit',
  minHeight: '650px',
  border: '1px solid #000',
  borderRadius: '21px',
  backgroundImage: 'url(./2.png)',
  backgroundPosition: 'right',
  backgroundSize: 'auto',
};

export const MainModal = ({
  modalType,
  openModal,
  disableBackdropClick,
  state,
  handleClose,
}: MainModalProps) => {
  const childComponent: { [x: string]: ReactElement } = {
    login: <LoginModal onClose={handleClose} props={state} />,
    signup: <SignupModal onClose={handleClose} />,
    logout: <Logout onClose={handleClose} props={state} />,
    gameSession: <GameSession onClose={handleClose} props={state} />,
    profile: <Profile onClose={handleClose} />,
    history: <GameHistoryModal onClose={handleClose} props={state} />,
    chooseBalance: <ChooseBalance onClose={handleClose} props={state} />,
    deposit: <DepositModal onClose={handleClose} props={state} />,
    levels: <LevelsModal onClose={handleClose} props={state} />,
    notification: <NotificationModal onClose={handleClose} props={state} />,
  };

  const onHandleClose = (event: React.MouseEvent<HTMLElement>, reason: string) => {
    if (
      (disableBackdropClick && reason === 'backdropClick') ||
      modalType === 'gameSession' ||
      modalType === 'gameSessionNeedOpenGame'
    ) {
      console.log(modalType, 'modalType');
      return false;
    }
    handleClose();
  };

  const additionalStyles = { ...(state?.data?.style ?? {}) };
  return (
    <Modal
      className={styles.mainModal}
      open={openModal}
      onClose={onHandleClose}
      sx={{ minWidth: 400 }}
    >
      <Box
        sx={{
          ...style,
          ...additionalStyles,
          bgcolor: color.secondaryBlue,
          minHeight: modalType === 'gameSession' ? '260px' : 'auto',
          padding: modalType === 'gameSession' ? '24px' : '12px',
        }}
        className={styles.content}
      >
        {childComponent[state?.currentModal]}
        {state?.currentModal !== 'gameSession' ? <IconClose onClose={handleClose} /> : null}
      </Box>
    </Modal>
  );
};
