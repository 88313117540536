import { Box, Collapse, List, ListItemButton, ListItemText, Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import StatisticsItem from './statisticsItem';
import { useTranslation } from 'react-i18next';
import styles from './collapseStatistics.module.scss';

interface CollapseStatisticsProps {
  classes?: string;
  title?: string;
  tabMenu?: any[];
  tabContent?: any[];
  translateFrom: string;
  isVertical?: boolean;
  activeTab?: string;
}

function CollapseStatistics({ tabMenu, title, translateFrom, activeTab }: CollapseStatisticsProps) {
  const { t } = useTranslation(translateFrom);
  const [isOpened, setIsOpened] = useState(false);
  const [activeItem, setActiveItem] = useState<any>();
  const [value, setValue] = useState('');

  const handleOnClick = () => {
    setIsOpened((prev) => !prev);
  };

  const handleOnItemClick = (index: number, id: string) => {
    setIsOpened((prev) => !prev);
    const selectedItem = tabMenu?.filter((el) => el.id === id)?.[0] ?? '';
    setActiveItem(selectedItem);
    setValue(index + '');
  };
  
  useEffect(() => {
    setValue(activeTab ?? '0');
  }, [activeTab]);

  return (
    <>
      {title ? (
        <Box className={styles.title}>
          <Typography align="center">{title}</Typography>
        </Box>
      ) : null}
      <Box className={styles.main}>
        <ListItemButton onClick={handleOnClick}>
          <ListItemText
            primary={t(activeItem ? activeItem?.name : tabMenu?.[0].name)}
            sx={{
              span: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }}
          />
          {isOpened ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        {tabMenu?.map(({ name, id }, index: number) => {
          return (
            <Collapse key={`subItem${index}`} in={isOpened} timeout="auto" unmountOnExit>
              <List component="div">
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleOnItemClick(index, id)}>
                  <ListItemText
                    primary={t(name)}
                    sx={{
                      span: {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  />
                </ListItemButton>
              </List>
            </Collapse>
          );
        })}
      </Box>

      <StatisticsItem value={value} translaste={'statistics'} isMobile />
    </>
  );
}

export default CollapseStatistics;
