import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab, Typography } from '@mui/material';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import WinnersCard from 'components/winnersCard/winnersCard';
import { Navigation, Pagination, Autoplay, A11y } from 'swiper/modules';
import brilliantIcon from 'assets/icons/winners/brilliant.svg';
import last_24_hoursIcon from 'assets/icons/winners/last_24_hours.svg';
import top_winnersIcon from 'assets/icons/winners/top_winners.svg';
import colors from 'styles/colors.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSelector } from 'react-redux';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useStatistics } from 'hooks/statistics/useStatistics';
import styles from './winnersTab.module.scss';

interface WinnersTabProps {
  className?: string;
  isMobile?: boolean;
}

const style = {
  overflow: 'hidden',
  overflowY: 'auto',
  overflowX: 'hidden',
  padding: 0,
  width: 'inherit',
};

const breakPoints = {
  320: {
    slidesPerView: 2,
  },
  640: {
    slidesPerView: 4,
  },
  768: {
    slidesPerView: 4,
  },
  1200: {
    slidesPerView: 4,
  },
  3000: {
    slidesPerView: 4,
  },
};

const renderLabel = (style: string, src: string, title: string) => {
  return (
    <>
      <img className={style} src={src} alt="icons" width={24} />
      <Typography component="span" sx={{ fontSize: '9px', m: 0.5, textTransform: 'none' }}>
        {title}
      </Typography>
    </>
  );
};

const renderTabContent = (val: string, statisticsData: any, isMobile?: boolean) => {
  return (
    <Box sx={{ pt: 2 }}>
      <Swiper
        breakpoints={{ ...breakPoints }}
        modules={[Autoplay, Navigation, Pagination, A11y]}
        direction={isMobile ? 'horizontal' : 'vertical'}
        spaceBetween={isMobile ? 5 : 20}
        slidesPerView={5}
        // autoplay
        loop
        scrollbar={{ draggable: true }}
        className={cx(styles.sliderWrapperVertical, { [styles.special_height]: isMobile })}
      >
        {statisticsData[+val]?.map((item: any, index: number) => {
          return (
            <SwiperSlide key={index}>
              <Box className={styles.winners_block}>
                <WinnersCard data={item} />
                <Box sx={{ ml: 2, width: '110px', display: 'flex', flexDirection: 'column' }}>
                  <Typography sx={{ width: '80px' }}>{item.login} </Typography>
                  <Box className={styles.divider} />
                  <Typography className={styles.amount}>{item.winAmount}</Typography>
                </Box>
              </Box>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  );
};
export default function WinnersTab({ isMobile }: WinnersTabProps) {
  const { getLifeTopWinners } = useStatistics();
  const [value, setValue] = useState('0');
  const topWinnerList = useSelector((state: any) => state.topWinnerList);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const getData = async () => {
    await getLifeTopWinners();
  };

  useEffect(() => {
    if (!topWinnerList.length) getData();
  }, []);

  return (
    <Box sx={{ width: '100%', typography: 'body1', mt: isMobile ? 0 : 2 }}>
      <TabContext value={value}>
        <TabList
          sx={{
            '.MuiTabs-indicator': {
              height: '1px',
              backgroundColor: colors.mainOrange,
            },
            '.MuiTabs-flexContainer': {
              justifyContent: 'center',
            },
          }}
          onChange={handleChange}
          aria-label="winners"
        >
          <Tab
            className={styles.tab_btn}
            label={renderLabel(styles.svg, last_24_hoursIcon, 'Last winners')}
            value="0"
          />

          <Tab
            className={styles.tab_btn}
            label={renderLabel(styles.svg, top_winnersIcon, 'Top winners')}
            value="1"
          />

          <Tab
            className={styles.tab_btn}
            label={renderLabel(styles.svg, brilliantIcon, 'Big winners')}
            value="2"
          />
        </TabList>

        <TabPanel value={value} sx={{ ...style }}>
          {renderTabContent(value, topWinnerList, isMobile)}
        </TabPanel>
      </TabContext>
    </Box>
  );
}
