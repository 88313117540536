import http from '../http-common';

class CategoryDataService {
  getAllGamesByCategoryId = (headers, siteId, categoryId, query = '') => {
    return http.get(
      `/site/${siteId}/category/${categoryId}/games/all?sortBy=order&sortDir=ASC${query}`,
      { headers },
    );
  };
}

const CategoryService = new CategoryDataService();

export default CategoryService;
