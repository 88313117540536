import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import bonusBalance from 'assets/games/bonus.webp';
import gameBalance from 'assets/games/game.webp';
import disabledBonus from 'assets/games/disabledBonus.webp';
import { ModalState, useModalContext } from 'context/modal/modalContext';
import { dialog } from 'constants/common';
import { FIELD_NAME } from 'types/payment';
import { useTranslation } from 'react-i18next';
import { Fragment, useEffect, useState } from 'react';
import { routing } from 'constants/routing';
import _color from 'styles/colors.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { IconClose } from 'components/closeButton/closeButton';
import { updateUserBalance } from 'redux/actions/userDataAction';
import Result from 'components/alert/alert';
import { RESULT } from 'types/common';
import { IUserBalance } from 'types/user';
import { isNumber } from 'utils/validations/validationTypes';
import { useUserData } from 'hooks/user/useUserData';
import { useStorage } from 'hooks/authHooks/useStorage';
import { activeTab, modalTypesObj } from 'components/navBar/navBar';
import logoMobile from 'assets/logo/logo.webp';
import cx from 'classnames';
import styles from './chooseBalance.module.scss';

interface ChooseBalanceProps {
  onClose: () => void;
  props?: ModalState;
}

const fields = {
  [FIELD_NAME.AMOUNT]: true,
};

const mocked_DenominatioList = [
  { id: '1', value: 10, label: '1 = 0.1' },
  { id: '2', value: 25, label: '1 = 0.25' },
  { id: '3', value: 50, label: '1 = 0.5' },
  { id: '4', value: 100, label: '1 = 1' },
  { id: '5', value: 200, label: '1 = 2' },
  { id: '6', value: 500, label: '1 = 5' },
];

export const ChooseBalance = ({ onClose, props }: ChooseBalanceProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const { t: menuT } = useTranslation('menu');
  const { openModalWithData } = useModalContext();
  const { trasferUserBalance, error } = useUserData();
  const { updateData } = useStorage();
  const [bonusChecked, setBonusChecked] = useState(true);
  const [balanceId, setBalanceId] = useState('');
  const [gameChecked, setGameChecked] = useState(true);
  const [showDenomination, setShowDenomination] = useState(false);
  const [denominationValue, setDenominationValue] = useState('100');
  const [amount, setAmount] = useState('');
  const [isTranferBalanceActive, setIsTransferBalanceActive] = useState(false);
  const [validFields, setValidFields] = useState({ ...fields });
  const dispatch = useDispatch();

  let timeOutId: ReturnType<typeof setTimeout>;

  const userData = useSelector<any>((state) => state.userData) as any;

  const navigatePlay = (id: string, denomination?: string) => {
    if (props?.data) {
      const state = {
        ...props.data,
        balanceId: balanceId ? balanceId : id,
        denomination: denomination ?? null,
      };
      navigate(`${routing.game}`, {
        state,
      });
    }
  };

  const handleDenomination = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const target = e.target as HTMLTextAreaElement;
    setDenominationValue(target.value);
  };

  const handleOnOpenBonuses = () => {
    openModalWithData(modalTypesObj['balance'], { activeTab: activeTab['bonuses'] });
  };

  const handlePlayGame = () => {
    onClose();
    navigatePlay(balanceId, denominationValue);
  };

  const hadleOnClick = (e: React.ChangeEvent<HTMLInputElement>, type: string, id: string) => {
    const { value } = e.target;
    if (type === 'bonus') {
      setBonusChecked(value === 'on');
      setGameChecked(!(value === 'on'));
    } else {
      setBonusChecked(!(value === 'on'));
      setGameChecked(value === 'on');
    }
    if (props?.data.providerId !== 2) {
      timeOutId = setTimeout(() => {
        navigatePlay(id);
        onClose();
      }, 500);
    } else {
      timeOutId = setTimeout(() => {
        setBalanceId(id);
        setShowDenomination(true);
      }, 500);
    }
  };

  const handleOnChangeAmount = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    if (isNumber(value) || value === '') {
      setAmount(value.trim());
    }
    resetValidFields(FIELD_NAME.AMOUNT);
  };

  const transferBalance = async (id: string) => {
    const balance = userData?.balance?.filter((el: { type: FIELD_NAME }) => el.type === 'wallet');
    const body = { amount, from: balance[0].id, to: id };
    const result = await trasferUserBalance(body);

    if (result?.status === 200) {
      updateData({ balance: result?.data });
      dispatch(updateUserBalance(result.data));
      setAmount('');
      setGameChecked(true);
      setBonusChecked(false);
      if (props?.data.providerId !== 2) {
        timeOutId = setTimeout(() => {
          navigatePlay(id);
          onClose();
        }, 500);
      } else {
        timeOutId = setTimeout(() => {
          setBalanceId(id);
          setShowDenomination(true);
        }, 500);
      }
    }
  };

  const handleActivateBalanceTransfer = () => {
    setIsTransferBalanceActive((isActive) => !isActive);
  };

  const validate = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, name: string) => {
    const { value } = e.target;
    const balance = userData?.balance?.filter((el: { type: FIELD_NAME }) => el.type === 'wallet');

    if (value !== '') {
      setValidFields((fields) => ({
        ...fields,
        [name]: +value <= +balance?.[0].amount && +value >= 1,
      }));
    }
  };

  const resetValidFields = (name: string) => {
    setValidFields((fields) => ({
      ...fields,
      [name]: true,
    }));
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeOutId);
    };
  }, []);

  return (
    <Box className={styles.content}>
      <Box className={styles.modal_title}>
        <img src={logoMobile} alt="logo" width={128} />
        <Typography align="center" sx={{ mt: 1 }}>
          {!showDenomination ? t(dialog.CHOOSE_BALANCE) : t(dialog.SELECT_DENOMINATION)}
        </Typography>
        {!isTranferBalanceActive && userData?.balance?.[1].amount > 1 && !showDenomination && (
          <Button
            onClick={handleActivateBalanceTransfer}
            sx={{
              backgroundColor: 'orange',
              color: 'white',
              justifyContent: 'center',
              mt: 1,
            }}
          >
            {t('transferFromWallet')}
          </Button>
        )}
      </Box>
      {showDenomination ? (
        <Box className={styles.action_demonination}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demonination"
              defaultValue="top"
              sx={{ justifyContent: 'center' }}
            >
              {mocked_DenominatioList.map((el, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    value={el.id}
                    checked={el.value === +denominationValue}
                    control={<Radio size="small" value={el.value} onClick={handleDenomination} />}
                    label={el.label}
                    labelPlacement="bottom"
                    className={cx({ [styles.isActive]: el.value === +denominationValue })}
                    sx={{
                      '&.Mui-checked': { color: _color.mainwarning },
                    }}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>

          <Box className={styles.btn}>
            <Button fullWidth onClick={handlePlayGame} disabled={denominationValue === ''}>
              {t('play')}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box className={styles.action}>
          {userData?.balance.map((item: IUserBalance, index: number) => {
            return (
              <Fragment key={index}>
                {item.type !== 'wallet' ? (
                  <Box className={item.type === 'bonus' ? styles.bonus : styles.game}>
                    {item.type === 'game' && isTranferBalanceActive && (
                      <IconClose onClose={handleActivateBalanceTransfer} />
                    )}
                    <Box className={styles.img}>
                      <img
                        width="150px"
                        src={
                          item.type === 'game'
                            ? gameBalance
                            : item.amount <= 1
                              ? disabledBonus
                              : bonusBalance
                        }
                        alt={item.type}
                      />
                      <Typography textAlign={'center'} sx={{ mt: 2 }}>
                        <Typography
                          component={'span'}
                          color={
                            item.amount <= 1 && item.type === 'bonus'
                              ? _color.mainGrey
                              : _color.mainOrange
                          }
                        >
                          {t(
                            `choose${
                              item.type === 'game' && (item.amount <= 1 || isTranferBalanceActive)
                                ? userData?.balance[0].type
                                : item.type
                            }`,
                          )}
                          :{' '}
                        </Typography>
                        {item.type === 'game' && (item.amount <= 1 || isTranferBalanceActive)
                          ? userData?.balance[0].amount
                          : item.amount}{' '}
                        {userData?.currency?.code}
                      </Typography>
                      {(item.amount <= 1 || isTranferBalanceActive) && item.type === 'game' && (
                        <Typography>
                          <Typography
                            component={'span'}
                            color={item.amount < 1 ? _color.mainGrey : _color.mainOrange}
                          >
                            {t(`choose${item.type}`)}:{' '}
                          </Typography>
                          {item.amount} {userData?.currency?.code}
                        </Typography>
                      )}
                    </Box>
                    {item.amount > 1 && (!isTranferBalanceActive || item.type === 'bonus') ? (
                      <div className={styles.checkbox_wrapper}>
                        <input
                          type="checkbox"
                          id={`_checkbox-${item.type}`}
                          checked={item.type === 'bonus' ? !gameChecked : !bonusChecked}
                          onChange={(e) => hadleOnClick(e, item.type, item.id)}
                        />
                        <label htmlFor={`_checkbox-${item.type}`}>
                          <div className={styles.tick_mark} />
                        </label>
                      </div>
                    ) : item.type === 'game' ? (
                      <Box mt={2}>
                        <Box className={styles.transfer_input}>
                          <TextField
                            size="small"
                            fullWidth
                            label={
                              validFields[FIELD_NAME.AMOUNT] ? t('amount') : t('notEnoughBalance')
                            }
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={amount}
                            onChange={handleOnChangeAmount}
                            onBlur={(e) => validate(e, FIELD_NAME.AMOUNT)}
                            error={!validFields[FIELD_NAME.AMOUNT]}
                          />
                          <Box className={styles.currency_name}>{userData?.currency?.code} </Box>
                        </Box>
                        <Box sx={{ textAlign: 'center' }}>
                          <Button
                            disabled={!validFields[FIELD_NAME.AMOUNT]}
                            onClick={() => transferBalance(item.id)}
                            sx={{
                              marginTop: '10px',
                              width: '90%',
                              backgroundColor: _color.green,
                              color: 'white',
                            }}
                          >
                            {t('transfer')}
                          </Button>
                        </Box>
                      </Box>
                    ) : (
                      <Button
                        className={styles.bonus_button}
                        fullWidth
                        onClick={handleOnOpenBonuses}
                      >
                        {menuT('myBonuses')}
                      </Button>
                    )}
                  </Box>
                ) : null}
              </Fragment>
            );
          })}
        </Box>
      )}
      {error && <Result style={{ mb: 2, mt: 2 }} message={error} type={RESULT.ERROR} isShow />}
    </Box>
  );
};
