import dayjs from 'dayjs';
import isEmail from 'validator/lib/isEmail';
import isUrl from 'validator/lib/isURL';

export const isValidUrl = (value) => value && isUrl(value);

export const isGreaterThan = (num) => (value) => value && value > num;

export const isValidEmail = (value) => value && isEmail(value);

export const isNumber = (value) => !(value && Number.isNaN(Number(value)));

export const minLength = (min) => (value) => value && value.length > min;

export const isStartingWithPlus = (value) => {
  return value[0] === '+'
};

export const isAlphanumericWithSpace = (inputtxt) => {
  const letterNumber = /^[0-9a-zA-Z _]+$/;
  return inputtxt.match(letterNumber);
};

export const isAlphanumeric = (inputtxt) => {
  const letterNumber = /^[0-9a-zA-Z]+$/;
  return inputtxt.match(letterNumber);
};

export const isCardNumber = (inputtxt) => {
  //eslint-disable-next-line
  const letterNumber = /^[0-9\-]+$/;
  return inputtxt.match(letterNumber);
};

export const isValidBirthdayDate = (value) => {
  const currentYear = dayjs().year();
  return currentYear - value.year() > 18
}

export const isValidPhonePattern = (inputtxt) => {
  const letterNumber = /[0-9]+$/;
  return inputtxt.match(letterNumber);
};