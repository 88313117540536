import { useStorage } from 'hooks/authHooks/useStorage';
import { useApiErrors } from 'hooks/errors/useApiErrors';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUserData } from 'redux/actions/userDataAction';
import UserService from 'services/user.service';
import { generateHeaders } from 'utils/utils';
import { useUser } from './useUser';

function useVerification(id?: string | undefined) {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { userToken } = useStorage();
  const dispatch = useDispatch();
  const { removeUser } = useUser();
  const { errorHandler } = useApiErrors();
  const initHeaders = generateHeaders(userToken());

  const sendVerificationData: any = async (verifyData: any, headers = initHeaders) => {
    const header = { ...headers ,'Content-Type': 'multipart/form-data'}
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await UserService.sendVerificationImages(
        header,
        verifyData,
      );
      if (status === 200) {
        setIsLoading(false);
        dispatch(setUserData(data));
      }
      return { status };
    } catch (error: any) {
      if (error.response) {
        const {
          data: { message, status },
        } = error.response;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await sendVerificationData(verifyData, newHeader);
        } else {
          console.log('LOG____> sendVerificationData', message);
          setError(message);
        }
      }
    }
  };

  const getUserData: any = async (headers = initHeaders) => {
    setIsLoading(true);
    try {
      const {
        data: { data, status },
      } = await UserService.getUserData(headers);
      if (status === 200) {
        dispatch(setUserData(data));
      }
    } catch (error: any) {
      if (error.response) {
        const {
          data: { message, status },
        } = error.response;
        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await getUserData(newHeader);
        } else {
          console.log('LOG____> getUserData', message);
          setError(message);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getUserData();
    }
  }, [id]);

  return {
    sendVerificationData,
    isLoading,
    error,
  };
}

export default useVerification;
