export enum FIELD_NAME {
  CARD = 'card',
  MIN = 'min',
  BONUS= 'bonus',
  GAME = 'game',
  WALLET = 'wallet',
  FROM ='from',
  TO = 'to',
  AMOUNT ='amount'
}

export interface IPayCurrency {
  currencyCode: string;
  currencyId: number;
  currencyName: string;
  maxValue: number;
  minValue: number;
}

export interface IPayMethods {
  id: string;
  sitePayInMethods: IPayCurrency[];
  sitePayOutMethods: IPayCurrency[];
  image: string;
  name: string;
}