import http from '../http-common';
import http2 from 'http2-common';

class ProviderDataService {

  getAllProviders = (headers) => {
    return http.get(`/game-providers/sub-providers`, { headers });
  };

  getAllSubProvidersByCategoryId = (headers, categoryId, query='') => {
    return http2.get(`/games/sub-providers/category/${categoryId}?${query}`, { headers });
  };

  getAllProvidersWithoutGames = (headers) => {
    return http2.get(`/games/sub-provider-names/all`, { headers });
  }
}

const ProviderService = new ProviderDataService();

export default ProviderService;
