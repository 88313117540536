import http from '../http-common';
import http2 from '../http2-common';


class AuthDataService {
  login = (data) => http2.post('/auth/login', data);
  signup = (data) => http2.post('/users', data);

  refreshToken = (data) => http2.post('/auth/refresh', data);
}

const AuthService = new AuthDataService();

export default AuthService;
