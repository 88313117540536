import { TABLET } from 'components/sections/topSection';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setDeviceType } from 'redux/actions/deviceTypeActions';

const useDeviceDetection = () => {
  const [device, setDevice] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    const handleDeviceDetection = () => {
      let device = '';

      const userAgent = navigator.userAgent.toLowerCase();
      const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(userAgent);
      const isTablet = /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent);
      
      if (isTablet || isMobile || window.innerWidth <= TABLET) {
        device = 'Mobile';
      } else {
        device = 'Desktop';
      }
      setDevice(device);
      dispatch(setDeviceType(device));
    };

    handleDeviceDetection();
    window.addEventListener('resize', handleDeviceDetection);

    return () => {
      window.removeEventListener('resize', handleDeviceDetection);
    };
  }, []);

  return device;
};

export default useDeviceDetection;
