export const auth = {
  AUTHORIZATION_HEADER_REQUIRED: 'authorization_header_required',
  AUTHORIZATION_TOKEN_EXPIRED: 'authorization_token_expired',
  AUTHORIZATION_INTERNAL_ERROR: 'authorization_internal_error',
  REFRESH_TOKEN_SUCCESS: 'refresh_token_success',
  REFRESH_TOKEN_BAD_REQUEST: 'refresh_token_bad_request',
  REFRESH_TOKEN_EXPIRED: 'refresh_token_expired',
  REFRESH_TOKEN_INTERNAL_ERROR: 'refresh_token_internal_error',
  REFRESH_TOKEN_WRONG: 'refresh_token_wrong',
  REQUEST_REFRESH_EXPIRED: 'Request failed with status code 403',
  REQUEST_REFRESH_WRONG: 'Request failed with status code 401',
};

type IErrors = {
  [key: string]: string;
};

export const login: IErrors = {
  EMPTY_DATA: 'There is an empty data',
  INCORRECT_LENGTH: 'Incorrect length',
  login_user_blocked: 'accountIsBlocked',
  login_user_not_found: 'loginAccountNotFound',
  login_user_wrong_password: 'wrongPassword',
  login_account_wrong_two_factor_token: 'Wrong verification code',
};

export const signup: IErrors = {
  USER_ALREADY_EXIST: 'userAlreadyExist',
  login_account_wrong_two_factor_token: 'Wrong verification code',
};

export const payOutErrors: IErrors = {
  'not enough money': 'notEnoughMoney',
  'amount is below limit': 'amountIsBelowLimit',
};
