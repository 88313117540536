import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react';
import { faqs } from './mocked_data';
import FaqCard from './Card/card';
import styles from './faq.module.scss';

function Faq() {
  const { t } = useTranslation('common');

  return (
    <Box className={styles.terms_area}>
      <Box className={styles.titles_info}>
        <Box>
          <Typography variant="h4">Часто задаваемые вопросы</Typography>
        </Box>
      </Box>
      <Box className={styles.content}>
        {faqs.map((el, index) => {
          return (
            <Fragment key={index}>
              <FaqCard {...el} index={index} />
            </Fragment>
          );
        })}
      </Box>
    </Box>
  );
}

export default Faq;
