import { Box, Typography } from '@mui/material';
import { useTerms } from 'hooks/terms/useTerms';
import { RawDraftContentState, convertFromRaw } from 'draft-js';
import { useEffect, useRef, useState } from 'react';
import { stateToHTML } from 'draft-js-export-html';
import { useStorage } from 'hooks/authHooks/useStorage';
import { useTranslation } from 'react-i18next';
import styles from './terms.module.scss';

interface Term {
  term: RawDraftContentState;
}

function Terms() {
  const { terms } = useTerms();
  const termData = useRef<HTMLDivElement>(null);
  const { t: footerT } = useTranslation('footer');
  const { t } = useTranslation('common');
  const { readStorageData } = useStorage();
  const [htmlCode, setHtmlCode] = useState<any>(null);

  useEffect(() => {
    const termData: Term | undefined = terms.filter(
      (el: any) => el.code === readStorageData('siteLanguage'),
    )?.[0] as unknown as Term;
    if (termData && termData.term) {
      const contentState = convertFromRaw(termData.term);
      const html = stateToHTML(contentState);
      setHtmlCode(html);
    } else {
      setHtmlCode(null);
    }
  }, [terms, readStorageData('siteLanguage')]);

  return (
    <Box className={styles.terms_area}>
      <div
        ref={termData}
        className={styles.terms_data}
        dangerouslySetInnerHTML={{ __html: htmlCode ? htmlCode : t('underConstr') }}
      />
    </Box>
  );
}

export default Terms;
