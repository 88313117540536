import Login from 'components/login/login';
import { ModalState } from 'context/modal/modalContext';

interface LoginModalProps {
  onClose: () => void;
  props?: ModalState
}

export const LoginModal = ({ onClose, props}: LoginModalProps) => {
  return <Login onClose={onClose}  props={props} />;
};
