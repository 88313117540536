import { IconButton } from '@mui/material';
import cx from 'classnames';
import CloseIcon from '@mui/icons-material/Close';
import style from './closeButton.module.scss';

interface CloseIconProps {
  onClose: () => void;
  className?: string;
}

export function IconClose({ onClose, className }: CloseIconProps) {
  return (
    <IconButton className={cx(style.icon, className)} onClick={onClose}>
      <CloseIcon />
    </IconButton>
  );
}
