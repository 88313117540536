import { useEffect, useLayoutEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import ResponsiveNavBar from 'components/navBar/navBar';
import { Box, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { light } from 'styles/theme/light';
import NoMatch from 'components/notFound/notFound';
import { routing } from 'constants/routing';
import { useSiteConfig } from 'hooks/configHooks/useConfig';
import { MainModal } from 'components/modals/mainModal';
import Terms from 'components/terms/terms';
import { io, Socket } from 'socket.io-client';
import { ClientToServerEvents, ServerToClientEvents } from 'types/socket/socket';
import { useAuthContext } from 'context/auth/authContext';
import { useGame } from 'hooks/playGames/useGame';
import useDeviceDetection from 'hooks/deviceDetection/useDeviceDetection';
import { useDispatch } from 'react-redux';
import { useModalContext } from 'context/modal/modalContext';
import Footer from 'components/footer/footer';
import AboutUs from 'components/aboutUs/aboutUs';
import { GamesPage } from 'components/pageLayout/games/games';
import { SlotsPage } from 'components/pageLayout/slots/slots';
import { CategoryPage } from 'components/pageLayout/category/category';
import { PlayGame } from 'components/pageLayout/playGame/playGame';
import { GameDemo } from 'components/pageLayout/gameDemo/gameDemo';
import SubProvider from 'components/pageLayout/subProvider/subProvider';
import { wsURL } from 'ws-connection';
import Statistics from 'components/pageLayout/statistics/statistics';
import { setUserData, updateUserBalance } from 'redux/actions/userDataAction';
import LoaderWithBg from 'components/loader/loader';
import { BonusesPage } from 'components/pageLayout/bonuses/bonuses';
import BonusTerms from 'components/pageLayout/bonusTerms/bonusTerms';
import { User } from 'types/user';
import Payments from 'components/pageLayout/payments/payments';
import { WheelPage } from 'components/wheel/wheel';
import PaymentFailed from 'components/pageLayout/paymentFailed/paymentFailed';
import { useBalance } from 'hooks/user/useBalance';
import { useStorage } from 'hooks/authHooks/useStorage';
import Faq from 'components/pageLayout/faq/faq';
import './App.scss';

export const socket: Socket<ServerToClientEvents, ClientToServerEvents> = io(wsURL ?? '');

interface SensitiveRouteProps {
  user: User | null;
  children: React.ReactNode;
}

const SensitiveRoute = ({ user, children }: SensitiveRouteProps) => {
  if (!user) return <Navigate replace to={routing.home} />;
  return <>{children}</>;
};

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useDeviceDetection();
  const { getSiteConfig } = useSiteConfig();
  const { getUserBalance } = useBalance();
  const { updateData } = useStorage();
  const { isLoading } = useGame();
  const [activeContent, setActiveContent] = useState('');
  const { user } = useAuthContext();
  const { state, modalType, isModalOpened, closeModal } = useModalContext();

  const onHandleClick = (value: string) => {
    setActiveContent(value);
  };

  const updateBalance = async () => {
    const res = await getUserBalance();
    if (res) {
      dispatch(updateUserBalance(res.data));
      updateData({ balance: res.data });
    }
  };

  useEffect(() => {
    socket.on('closeSession', () => {
      navigate(`${routing.home}`);
    });

    getSiteConfig();
    if (user) {
      updateBalance();
      dispatch(setUserData(user.user));
    }
  }, []);

  useEffect(() => {
    if (user && activeContent !== '') {
      setActiveContent('');
    }
    if (user) {
      socket.emit('user', { userId: user.user.id });
    }
  }, [user]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <ThemeProvider theme={light}>
      <CssBaseline />
      <Box className="App">
        <ResponsiveNavBar onHandleClick={onHandleClick} />
        <hr className="divider" />
        <Box className="App-container">
          {!isLoading ? (
            <Routes>
              <Route path={routing.home} element={<GamesPage />} />
              <Route path={routing.slots} element={<SlotsPage />} />
              {/* <Route path={routing.live} element={<WheelPage />} /> */}
              <Route path={routing.wheel} element={<WheelPage />} />
              <Route path={routing.bonuses} element={<BonusesPage />} />
              <Route path={routing.faq} element={<Faq />} />
              <Route path={routing.notFound} element={<NoMatch />} />
              <Route path={routing.terms} element={<Terms />} />
              <Route path={`${routing.category}/:id`} element={<CategoryPage />} />
              <Route path={`${routing.provider}/:id`} element={<SubProvider />} />
              <Route path={routing.aboutUs} element={<AboutUs />} />
              <Route path={routing.bonusTerms} element={<BonusTerms />} />
              <Route
                path={routing.game}
                element={
                  <SensitiveRoute user={user}>
                    <PlayGame />
                  </SensitiveRoute>
                }
              />
              <Route
                path={routing.payment}
                element={
                  <SensitiveRoute user={user}>
                    <Payments />
                  </SensitiveRoute>
                }
              />
              <Route path={`${routing.gameDemo}`} element={<GameDemo />} />
              <Route path={`${routing.statistics}`} element={<Statistics />} />
              <Route path={`${routing.paymentFailed}`} element={<PaymentFailed />} />
            </Routes>
          ) : (
            <Box sx={{ minHeight: '600px' }}>
              <LoaderWithBg isOpen={isLoading} />
            </Box>
          )}
        </Box>
        <MainModal
          state={state}
          modalType={modalType}
          openModal={isModalOpened}
          handleClose={closeModal}
        />
        <Box className="footer" mt={20}>
          <Footer />
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default App;
