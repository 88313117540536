import http2 from '../http2-common';

class PaymentDataService {
  payIn = (headers, methodId, body) => {
    return http2.post(`/finances/pay-in/${methodId}/process`, body, { headers });
  };

  payOut = (headers, methodId, body) => {
    return http2.post(`/finances/pay-out/${methodId}/process`, body, { headers });
  };

}

const PaymentService = new PaymentDataService();

export default PaymentService;
