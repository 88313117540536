import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './verificationStatusInfo.module.scss';

interface VerificationStatusInfoProps {
  verificationStatus?: string;
  rejectedReason?: string | null;
  emailConfirmedStatus?: boolean;
  phoneConfirmedStatus?: boolean;
}

function VerificationStatusInfo({
  verificationStatus,
  rejectedReason,
  emailConfirmedStatus,
  phoneConfirmedStatus,
}: VerificationStatusInfoProps) {
  const { t: profileT } = useTranslation('profile');
  const [colorForPassportStatus, setColorForPassportStatus] = useState<null | string>('grey');
  const [colorForPhoneStatus, setColorForPhoneStatus] = useState<null | string>(null);
  const [colorForEmailStatus, setColorForEmailStatus] = useState<null | string>(null);

  useEffect(() => {
    verificationStatus === 'unverified'
      ? setColorForPassportStatus('grey')
      : verificationStatus === 'inProcess'
        ? setColorForPassportStatus('orange')
        : verificationStatus === 'verified'
          ? setColorForPassportStatus('green')
          : setColorForPassportStatus('red');
    phoneConfirmedStatus
      ? setColorForPhoneStatus('green')
      : phoneConfirmedStatus !== undefined
        ? setColorForPassportStatus('red')
        : setColorForPhoneStatus(null);
    emailConfirmedStatus
      ? setColorForEmailStatus('green')
      : emailConfirmedStatus !== undefined
        ? setColorForEmailStatus('red')
        : setColorForEmailStatus(null);
  }, [verificationStatus, emailConfirmedStatus, phoneConfirmedStatus]);

  return (
    <Box className={styles.mainArea}>
      <Box className={styles.statusInfo}>
        <Typography
          component={'span'}
          sx={{
            backgroundColor: colorForPhoneStatus ?? colorForEmailStatus ?? colorForPassportStatus,
          }}
        />
        {verificationStatus
          ? profileT(verificationStatus as string)
          : emailConfirmedStatus || phoneConfirmedStatus
            ? profileT('confirmed')
            : profileT('notConfirmed')}
      </Box>
      {rejectedReason && (
        <Typography sx={{ textAlign: 'right', color: 'red', fontSize: '10px' }}>
          ({rejectedReason})
        </Typography>
      )}
    </Box>
  );
}

export default VerificationStatusInfo;
