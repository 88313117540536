import { useStorage } from 'hooks/authHooks/useStorage';
import { useApiErrors } from 'hooks/errors/useApiErrors';
import { useUser } from 'hooks/user/useUser';
import { useState } from 'react';
import TransactionService from 'services/transaction.service';
import { generateHeaders } from 'utils/utils';

export const useTransaction = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingPayIns, setIsLoadingPayIns] = useState<boolean>(false);
  const { removeUser } = useUser();
  const [historyList, setHistoryList] = useState<any>([]);
  const [methodsList, setMethodsList] = useState<any>([]);

  const [error, setError] = useState('');
  const { userToken } = useStorage();
  const { errorHandler } = useApiErrors();
  const initHeaders = generateHeaders(userToken());

  const getAllPayInMethods: any = async (query: string, headers = initHeaders) => {
    try {
      setIsLoadingPayIns(true);
      const {
        data: { data, status },
      } = await TransactionService.getAllPayInMethods(headers, query);
      if (status === 200) {
        setMethodsList(data);
        return { status, data };
      }
    } catch (error: any) {
      if (error.response) {
        const {
          response: {
            data: { message, status },
          },
        } = error;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await getAllPayInMethods(query, newHeader);
        } else {
          console.log('LOG____>', message);
          setError(message);
        }
      }
    } finally {
      setIsLoadingPayIns(false);
    }
  };

  const getAllPayOutMethods: any = async (query: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: { data, status },
      } = await TransactionService.getAllPayOutMethods(headers, query);
      setIsLoading(false);
      if (status === 200) {
        setMethodsList(data);
        return { status, data };
      }
    } catch (error: any) {
      if (error.response) {
        const {
          response: {
            data: { message, status },
          },
        } = error;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await getAllPayOutMethods(query, newHeader);
        } else {
          console.log('LOG____>', message);
          setError(message);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getAllDepositTransactions: any = async (query: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
        data: {
          data: { payInTransactions, totalPages },
          status,
        },
      } = await TransactionService.getAllDepositTransactions(headers, query);
      if (status === 200) {
        setHistoryList(payInTransactions);
        return { data: payInTransactions, totalPages  };
      }
    } catch (error: any) {
      if (error.response) {
        const {
          response: {
            data: { message, status },
          },
        } = error;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await getAllDepositTransactions(query, newHeader);
        } else {
          console.log('LOG____>', message);
          setError(message);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getAllWithdrawTransactions: any = async (query: string, headers = initHeaders) => {
    try {
      setIsLoading(true);
      const {
       data: {
          data: { payOutTransactions, totalPages },
          status,
        },
      } = await TransactionService.getAllWithdrawTransactions(headers, query);
      if (status === 200) {
        setHistoryList(payOutTransactions);
        return { data: payOutTransactions, totalPages  };
      }
    } catch (error: any) {
      if (error.response) {
        const {
          response: {
            data: { message, status },
          },
        } = error;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await getAllWithdrawTransactions(query, newHeader);
        } else {
          console.log('LOG____>', message);
          setError(message);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    methodsList,
    getAllPayInMethods,
    getAllPayOutMethods,
    isLoading,
    isLoadingPayIns,
    historyList,
    getAllDepositTransactions,
    getAllWithdrawTransactions,
    error,
  };
};
