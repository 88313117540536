import { AlertColor, Box, Button, TextField, Typography } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import cx from 'classnames';
import bonusImg from 'assets/games/bonus.webp';
import gameImg from 'assets/games/game.webp';
import walletImg from 'assets/games/wallet.webp';
import { useTranslation } from 'react-i18next';
import transfer from 'assets/icons/transfer.png';
import arrow from 'assets/icons/arrow.png';
import transfer1 from 'assets/icons/transf_mid.png';
import { isNumber } from 'utils/validations/validationTypes';
import { useSelector } from 'react-redux';
import { useDrag, useDrop } from 'react-dnd';
import Result from 'components/alert/alert';
import { FIELD_NAME } from 'types/payment';
import LoaderWithBg from 'components/loader/loader';
import { useUserData } from 'hooks/user/useUserData';
import { RESULT } from 'types/common';
import { requestResults } from 'constants/common';
import { useStorage } from 'hooks/authHooks/useStorage';
import common from 'styles/colors.module.scss';
import styles from './balanceTransfer.module.scss';

const fields = {
  [FIELD_NAME.AMOUNT]: true,
};

const imObj = {
  [FIELD_NAME.BONUS]: bonusImg,
  [FIELD_NAME.GAME]: gameImg,
  [FIELD_NAME.WALLET]: walletImg,
};

type ITYPE = FIELD_NAME.BONUS | FIELD_NAME.GAME | FIELD_NAME.WALLET;

function BalanceTransfer() {
  const { t } = useTranslation('deposit');
  const { t: commonT } = useTranslation('common');
  const { isLoading, trasferUserBalance, resetError, error } = useUserData();
  const { updateData } = useStorage();
  const [amount, setAmount] = useState('');
  const userData = useSelector<any>((state) => state.userData) as any;
  const [validFields, setValidFields] = useState({ ...fields });
  const [apiError, setApiError] = useState<string>('');
  const [type, setType] = useState<AlertColor>('success');
  const [from, setFrom] = useState<any>({ type: '', id: '' });
  const [to, setTo] = useState<any>({ type: '', id: '' });

  const resetValidFields = (name: string) => {
    setValidFields((fields) => ({
      ...fields,
      [name]: true,
    }));
  };

  const validate = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, name: string) => {
    const { value } = e.target;
    const balance = userData?.balance?.filter((el: { type: FIELD_NAME }) => el.type === from.type);

    if (from.type && to.type && value !== '') {
      setValidFields((fields) => ({
        ...fields,
        [name]: +value <= +balance?.[0].amount,
      }));
    }
  };

  const handleOnClose = () => {
    resetError();
    setApiError('');
  };

  const handleOnClick = async () => {
    if (from.type === 'bonus' && to.type === 'game') {
      setApiError(t('bonusToGame'));
      setType(RESULT.ERROR);
      return;
    }
    if (
      (from.type === 'wallet' && to.type === 'bonus') ||
      (from.type === 'game' && to.type === 'bonus')
    ) {
      setApiError(t('walletToBonus'));
      setType(RESULT.ERROR);
      return;
    }

    if (from.type && to.type && +amount !== 0) {
      const body = { amount, from: from.id, to: to.id };
      const result = await trasferUserBalance(body);
      if (result?.status === 200) {
        updateData({ balance: result?.data });
        setApiError(commonT(RESULT.SUCCESS));
        setType(RESULT.SUCCESS);
        setAmount('');
        handleOnItemClick('both');
      }
    }
  };

  const handleOnItemClick = (type: string) => {
    if (apiError === t('bonusToGame')) {
      setApiError('');
      setType(RESULT.SUCCESS);
    }
    if (type === 'both') {
      return setTo(''), setFrom('');
    }
    return type === 'to' ? setTo('') : setFrom('');
  };

  const handleOnClickBalance = (type: string, id: string) => {
    if (apiError === t('bonusToGame')) {
      setApiError('');
      setType(RESULT.SUCCESS);
    }

    if (!from.type) {
      setFrom({ type, id });
    } else {
      if (from.type !== type) {
        setTo({ type, id });
      }
    }
  };

  const handleOnChangeAmount = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    if (isNumber(value) || value === '') {
      setAmount(value.trim());
    }
    resetValidFields(FIELD_NAME.AMOUNT);
  };

  const renderFrom = (type: ITYPE) => {
    const img = imObj[type];
    const balance = userData?.balance?.filter((el: { type: FIELD_NAME }) => el.type === type);
    return (
      <Box className={cx(styles.balance_item)}>
        <Box sx={{ display: 'flex', mb: 1 }}>
          <img src={img} />
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Typography
            component="span"
            sx={{ m: 0, ':first-letter': { textTransform: 'uppercase' } }}
          >
            {commonT(type)} {balance?.[0].amount} {userData?.currency?.code}
          </Typography>
        </Box>
      </Box>
    );
  };

  const [{ isDraggingBonus }, dragBonus] = useDrag({
    type: 'BOX',
    item: { type: 'bonus' },
    collect: (monitor) => ({
      isDraggingBonus: !!monitor.isDragging(),
    }),
  });

  const [{ isDraggingGame }, dragGame] = useDrag({
    type: 'BOX',
    item: { type: 'game' },
    collect: (monitor) => ({
      isDraggingGame: !!monitor.isDragging(),
    }),
  });

  const [{ isDraggingWallet }, dragWallet] = useDrag({
    type: 'BOX',
    item: { type: 'wallet' },
    collect: (monitor) => ({
      isDraggingWallet: !!monitor.isDragging(),
    }),
  });

  const [{ canDropFrom, isOverFrom }, drop] = useDrop({
    accept: 'BOX',
    drop: (item: any) => {
      if (apiError === t('bonusToGame')) {
        setApiError('');
        setType(RESULT.SUCCESS);
      }

      const balance = userData?.balance?.filter(
        (el: { type: FIELD_NAME }) => el.type === item.type,
      );
      return to.type !== item.type ? setFrom({ ...item, id: balance?.[0]?.id ?? '' }) : null;
    },
    collect: (monitor) => ({
      canDropFrom: monitor.isOver(),
      isOverFrom: monitor.canDrop(),
    }),
  });

  const [{ canDropTo, isOverTo }, dropTo] = useDrop({
    accept: 'BOX',
    drop: (item: any) => {
      if (apiError === t('bonusToGame')) {
        setApiError('');
        setType(RESULT.SUCCESS);
      }

      const balance = userData?.balance?.filter(
        (el: { type: FIELD_NAME }) => el.type === item.type,
      );
      return from.type !== item.type ? setTo({ ...item, id: balance?.[0]?.id ?? '' }) : null;
    },
    collect: (monitor) => ({
      canDropTo: monitor.isOver(),
      isOverTo: monitor.canDrop(),
    }),
  });

  const isActiveFrom = canDropFrom && isOverFrom;
  const isActiveTo = canDropTo && isOverTo;

  useEffect(() => {
    if (amount) {
      const balance = userData?.balance?.filter(
        (el: { type: FIELD_NAME }) => el.type === from.type,
      );

      if (from.type && to.type) {
        setValidFields((fields) => ({
          ...fields,
          [FIELD_NAME.AMOUNT]: amount <= balance?.[0]?.amount,
        }));
      }
    }
  }, [from]);

  return (
    <Box className={styles.main_area}>
      {isLoading ? <LoaderWithBg isOpen={isLoading} /> : null}
      <Box className={styles.main_title}>
        {t('balanceTransfer')}
        <Box>
          <img src={transfer} />
        </Box>
      </Box>
      <Box className={styles.balance_block}>
        {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box className={styles.title}>{commonT('balanceTypes')}</Box>
          <Box className={styles.bullet} />
        </Box> */}
        <Box className={styles.balance_types_block}>
          {userData?.balance?.map(
            (el: { type: ITYPE; amount: number; id: string }, index: number) => {
              return (
                <Fragment key={index}>
                  <Box
                    ref={
                      el.type === 'bonus' ? dragBonus : el.type === 'game' ? dragGame : dragWallet
                    }
                    className={cx(styles.balance_item)}
                    style={{
                      opacity: (
                        el.type === 'bonus'
                          ? isDraggingBonus
                          : el.type === 'game'
                            ? isDraggingGame
                            : isDraggingWallet
                      )
                        ? 0.2
                        : el.type === from.type || el.type === to.type
                          ? 0.4
                          : 1,
                    }}
                    onClick={() => handleOnClickBalance(el.type, el.id)}
                  >
                    <Box sx={{ display: 'flex', mb: 1 }}>
                      <img src={imObj[el.type]} />
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                      <Typography
                        component="span"
                        sx={{ m: 0, ':first-letter': { textTransform: 'uppercase' } }}
                      >
                        {commonT(el.type)} {el.amount} {userData?.currency?.code}
                      </Typography>
                    </Box>
                  </Box>
                  {index < userData?.balance?.length - 1 ? (
                    <Box className={styles.middle_icon}>
                      <img src={transfer1} width={'26'} />
                    </Box>
                  ) : null}
                </Fragment>
              );
            },
          )}
        </Box>
        <Box className={styles.social}>
          <Box className={styles.divider_part}>
            <Box className={styles.divider} />
            <Box className={styles.or_text}>
              {t('dragBalance')} {t('orClick')}
            </Box>
            <Box className={styles.divider} />
          </Box>
        </Box>
        <Box>
          <Box className={styles.dragged_content}>
            <Box
              ref={drop}
              className={cx(styles.maket, { [styles.isdropped]: from?.type })}
              style={{
                backgroundColor: isActiveFrom ? 'lightgreen' : '',
              }}
              onClick={() => handleOnItemClick(FIELD_NAME.FROM)}
            >
              {from?.type ? (
                renderFrom(from.type)
              ) : (
                <Box sx={{ display: 'flex' }}>
                  <Typography component="span" sx={{ mr: 0.5 }}>
                    {t(FIELD_NAME.FROM)}
                  </Typography>
                </Box>
              )}
            </Box>
            <Box sx={{ display: 'flex', opacity: '0.5' }}>
              <img src={arrow} width={'16'} />
            </Box>
            <Box
              ref={dropTo}
              className={cx(styles.maket, { [styles.isdropped]: to?.type })}
              style={{ backgroundColor: isActiveTo ? 'lightgreen' : '' }}
              onClick={() => handleOnItemClick(FIELD_NAME.TO)}
            >
              {to?.type ? (
                renderFrom(to.type)
              ) : (
                <Box sx={{ display: 'flex' }}>
                  <Typography component="span" sx={{ mr: 0.5 }}>
                    {t(FIELD_NAME.TO)}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className={styles.amount_block}>
        <Box className={styles.promo}>
          <TextField
            size="small"
            fullWidth
            label={validFields[FIELD_NAME.AMOUNT] ? t('amount') : commonT('notEnoughBalance')}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={amount}
            onChange={handleOnChangeAmount}
            onBlur={(e) => validate(e, FIELD_NAME.AMOUNT)}
            error={!validFields[FIELD_NAME.AMOUNT]}
          />
          <Box className={styles.currency_name}>{userData?.currency?.code} </Box>
        </Box>
      </Box>
      <Box sx={{ mb: 1 }}>
        <Button
          sx={{ mt: 3, backgroundColor: common.darkGreen }}
          fullWidth
          variant="contained"
          onClick={handleOnClick}
          disabled={!validFields[FIELD_NAME.AMOUNT] || !(from.type && to.type)}
        >
          {commonT('transfer')}
        </Button>
      </Box>
      <Result
        style={{ mt: 1 }}
        message={apiError || commonT(requestResults[error])}
        type={error ? RESULT.ERROR : type}
        isShow={!!(apiError || error)}
        hasAction
        onClose={handleOnClose}
      />
    </Box>
  );
}

export default BalanceTransfer;
