import Deposit from 'components/deposit/deposit';
import { ModalState } from 'context/modal/modalContext';
import Notification from 'components/notification/notification';
import ModalContent from '../modalContent/modalContent';
import { useSelector } from 'react-redux';

interface DepositModalProps {
  onClose?: (type: string) => void;
  props?: ModalState;
}

export interface ITabs {
  id: string;
  name: string;
}

export interface IChildren {
  component: React.ReactNode;
}

const tabMenu = [
  { id: '1', name: 'notification' },
  // { id: '2', name: 'claims' },
];

function NotificationModal({ onClose, props }: DepositModalProps) {
  const userData = useSelector<any>((state) => state.userData) as any;

  const children = [
    {
      component: (
        <Notification
          forAllNotifications
          notificationList={userData.notifications.lastNotifications}
        />
      ),
    },
    { component: <Deposit /> },
  ];

  return (
    <ModalContent
      onClose={onClose}
      props={props}
      tabContent={children}
      tabMenu={tabMenu}
      translateFrom={'notification'}
    />
  );
}

export default NotificationModal;
