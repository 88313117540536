// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Navigation, Pagination, Autoplay, A11y } from 'swiper/modules';
import { Box } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import x1 from 'assets/banners/x1.webp';
import x2 from 'assets/banners/x2.webp';
import x01 from 'assets/banners/x01.webp';
import x02 from 'assets/banners/x02.webp';
import x001 from 'assets/banners/x001.webp';
import x002 from 'assets/banners/x002.webp';
import x0001 from 'assets/banners/x0001.webp';
import x0002 from 'assets/banners/x0002.webp';
import { Bonuses, Wallet, Games, Telegram } from 'assets/icons/menuIcons/index';
import { useEffect, useState } from 'react';
import { ISelectedBanner } from 'components/sections/topSection';
import cx from 'classnames';
import styles from './carouselVertical.module.scss';

export const carouselItems = [
  {
    id: '1',
    itemName: 'games',
    icon: Games,
    bannerImage: [x1, x2],
    titleText: ['Cамые популярные игры', 'Высокая отдача в ретро играх'],
    secondaryText: [''],
    button: [
      {
        buttonColor: 'red',
        text: 'Популярные игры',
        link: '/statistics',
        routeData: { activeTab: '0' },
      },
      {
        buttonColor: 'red',
        text: 'Играть',
        link: '/retro',
      },
    ],
  },
  {
    id: '2',
    itemName: 'telegram',
    icon: Telegram,
    bannerImage: [x01, x02],
    titleText: ['Круглосуточная связь ', 'Персональные менеджер'],
    secondaryText: ['с нашими сотрудниками в телеграмм'],

    button: [
      {
        type: 'external',
        buttonColor: 'red',
        text: 'Перейти в Телеграмм',
        link: 'https://t.me/magnitxcasino',
      },
      {
        type: 'tooltip',
        buttonColor: 'orange',
        text: 'Связаться с менеджером',
        links: [
          { name: 'VK менеджер', link: 'https://vk.com/magnixcasino' },
          { name: 'Е-майл', link: 'mailto:magnitt-x@mail.ru' },
          { name: 'Телеграмм', link: 'https://t.me/magnitxcom' },
        ],
      },
    ],
  },
  {
    id: '3',
    itemName: 'bonuses',
    titleText: ['', 'Мульти-промокоды и розыгрыши в Телеграмме'],
    icon: Bonuses,
    bannerImage: [x001, x002],
    secondaryText: [
      [
        'Бонусы каждый день на нашем телеграмм канале',
        'Подтвердите соц. сети и получите Бонус ',
        'Колесо фортуны каждый день до 1000 руб',
        'Индивидуальные бонусы от персонального менеджера',
      ],
    ],

    button: [
      {
        buttonColor: 'green',
        text: 'Бонусы',
        link: '/bonuses',
      },

      {
        type: 'external',
        buttonColor: 'red',
        text: 'Присоединиться',
        link: 'https://t.me/magnitxcasino',
      },
    ],
  },
  {
    id: '4',
    itemName: 'wallet',
    icon: Wallet,
    bannerImage: [x0001, x0002],
    titleText: ['Вы можете пополнить счет через наш Телеграмм', 'Вывод средств до 60 минут'],
    secondaryText: [''],
    button: [
      {
        buttonColor: 'red',
        type: 'external',
        text: 'Телеграмм',
        link: 'https://t.me/magnitxcom',
      },
      {
        type: 'openModal',
        modal: { modalName: 'deposit', activeTab: '2' },
        isSensitive: true,
        buttonColor: 'green',
        text: 'Вывод',
        link: '',
      },
    ],
  },
];

interface CarouselVertical {
  isCategorypage?: boolean;
  onChange?: () => void;
  lineStatus: string;
}

export function CarouselVertical({ onChange, lineStatus }: CarouselVerticalProps) {
  const [selectedItem, setSelectedItem] = useState<ISelectedBanner>(carouselItems[0]);

  useEffect(() => {
    onChange?.(selectedItem);
  }, [selectedItem]);

  return (
    <Box className={styles.main_container}>
      <Swiper
        modules={[Autoplay, Navigation, Pagination, A11y]}
        direction={lineStatus != 'horizontal' ? 'vertical' : 'horizontal'}
        slidesPerView={4}
        spaceBetween={lineStatus != 'horizontal' ? -64 : 0}
        navigation
        scrollbar={{ draggable: false }}
        className={styles.sliderWrapperVertical}
      >
        {carouselItems.map(
          ({ icon, itemName, bannerImage, titleText, secondaryText, button }, index) => {
            return (
              <SwiperSlide
                key={index}
                style={{ cursor: 'pointer' }}
                className={cx({ [styles.img_style]: selectedItem.itemName === itemName })}
              >
                <img
                  src={icon}
                  onClick={() =>
                    setSelectedItem((curr) => ({
                      ...curr,
                      itemName,
                      bannerImage,
                      titleText,
                      secondaryText,
                      button,
                    }))
                  }
                  width="32px"
                  alt="bannerIcon"
                />
              </SwiperSlide>
            );
          },
        )}
      </Swiper>
    </Box>
  );
}
