import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  useTheme,
  Box,
  TextField,
  Typography,
} from '@mui/material';
import common from 'styles/colors.module.scss';
import cx from 'classnames';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface IDialogsProps {
  id?: string;
  className?: string;
  title?: string;
  contentStyle?: any;
  content?: string | ReactNode;
  ignoreBtnText?: string;
  btnText?: string;
  placeholder?: string;
  isOpen: boolean;
  imageContent?: string;
  infoDialog?: boolean;
  isEmailConfirmed?: boolean;
  isPhoneConfirmed?: boolean;
  withForm?: boolean;
  onClose: () => void;
  onConfirm?: (id: string, comment?: string) => void;
  onChange?: (isConfirmed?: boolean, type?: string) => void;
  errorMessage?: string;
}

const Dialogs = ({
  title,
  content,
  id,
  isOpen,
  onClose,
  onConfirm,
  btnText,
  ignoreBtnText,
  contentStyle,
  infoDialog,
  imageContent,
  isEmailConfirmed,
  isPhoneConfirmed,
  withForm,
  placeholder,
  errorMessage,
  className,
  onChange,
}: IDialogsProps) => {
  const { t } = useTranslation('common');
  const theme = useTheme();

  const handleOnCLick = () => {
    if (onConfirm) {
      onConfirm(id as string);
    } else if (onChange) {
      if (typeof isEmailConfirmed !== 'undefined') {
        onChange(!isEmailConfirmed, 'email');
      } else {
        onChange(!isPhoneConfirmed, 'phone');
      }
    }
  };

  const escapeClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  return (
    <Dialog
      open={isOpen}
      onClick={!withForm ? onClose : escapeClick}
      PaperProps={
        withForm
          ? {
              component: 'form',
              onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                event.preventDefault();
                const formData = new FormData(event.currentTarget);
                const formJson = Object.fromEntries((formData as any).entries());
                const comment = formJson.comment;
                onConfirm?.(id as string, comment);
              },
            }
          : undefined
      }
    >
      {imageContent ? (
        <img src={imageContent} style={{ width: '100%', objectFit: 'contain' }} alt="image" />
      ) : (
        <Box className={cx(className)}>
          {title ? <DialogTitle>{title}</DialogTitle> : null}
          {withForm && !content ? (
            <DialogContent>
              <TextField
                sx={{ minWidth: '420px' }}
                autoFocus
                required
                margin="dense"
                id="comment"
                name="comment"
                placeholder={t(placeholder ? placeholder : 'comment')}
                fullWidth
                variant="standard"
              />
              {errorMessage ? (
                <Typography component={'p'} sx={{ fontSize: 10 }} color={common.mainwarning}>
                  {errorMessage}
                </Typography>
              ) : null}
            </DialogContent>
          ) : null}

          {content ? (
            <DialogContent sx={{ ...contentStyle }}>
              <DialogContent>{content}</DialogContent>
            </DialogContent>
          ) : null}

          {!infoDialog ? (
            <DialogActions sx={{ justifyContent: ' center' }}>
              {ignoreBtnText !== 'hide' ? (
                <Button variant="contained" onClick={onClose}>
                  {ignoreBtnText ?? t('cancel')}
                </Button>
              ) : null}
              <Button
                variant="contained"
                type={withForm ? 'submit' : 'button'}
                onClick={withForm ? () => null : handleOnCLick}
                autoFocus
                color={withForm ? 'success' : 'primary'}
                sx={{ m: 2 }}
              >
                {btnText ? t(btnText as string) ?? t('delete') : null}
              </Button>
            </DialogActions>
          ) : null}
        </Box>
      )}
    </Dialog>
  );
};

export default Dialogs;
