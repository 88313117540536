import { useApiErrors } from 'hooks/errors/useApiErrors';
import { useStorage } from 'hooks/authHooks/useStorage';
import { useEffect, useState } from 'react';
import { generateHeaders } from 'utils/utils';
import CategoryService from 'services/category.service';
import SiteService from 'services/site.service';
import { useUser } from 'hooks/user/useUser';
import { User } from 'types/user';
import { useDispatch } from 'react-redux';
import { setCategories } from 'redux/actions/categoryActions';

export const useCategory = (needCategories = false, user: User | null, deviceType = 'Desktop') => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState('');
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const { userToken } = useStorage();
  const { removeUser } = useUser();
  const { errorHandler } = useApiErrors();
  const dispatch = useDispatch();
  const initHeaders = generateHeaders(userToken());

  const getAllGamesByCategoryId: any = async (
    siteId: string,
    categoryId: string,
    query: string,
    headers = initHeaders,
  ) => {
    try {
      setIsLoading(true);

      const {
        data: {
          data: { game_categories, totalItems, totalPages },
          status,
        },
      } = await CategoryService.getAllGamesByCategoryId(headers, siteId, categoryId, query);

      setTotalPages(totalPages);
      setTotalItems(totalItems);
      return { data: game_categories, loading: false, status, totalPages };
    } catch (error: any) {
      if (error.response) {
        const {
          response: {
            data: { message, status },
          },
        } = error;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await getAllGamesByCategoryId(siteId, categoryId, query, newHeader);
        } else {
          console.log('LOG____>', message);
          setError(message);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getAllCategoryAndAllGames: any = async (
    query: string,
    user?: string,
    headers = initHeaders,
  ) => {
    try {
      setIsLoading(true);
      const {
        data: { data },
      } = await SiteService.getAllCategoryAndAllGames(headers, query, user);
      dispatch(setCategories(data));
    } catch (error: any) {
      if (error.response) {
        const {
          response: {
            data: { message, status },
          },
        } = error;

        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await getAllCategoryAndAllGames(query, user, newHeader);
        } else {
          console.log('LOG____getAllCategoryAndAllGames>', message);
          setError(message);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getData = async (user: any, query: string) => {
    if (!user) {
      await getAllCategoryAndAllGames(query);
    } else {
      await getAllCategoryAndAllGames(query, '/user?');
    }
  };

  useEffect(() => {
    if (needCategories && deviceType) {
      const isMobile = deviceType === 'Mobile';
      const query = isMobile
        ? `isMobile=${isMobile}&isGameCategoryMobile=${isMobile}`
        : 'isDesktop=true&isGameCategoryDesktop=true';
      getData(user, query);
    }
  }, [deviceType, needCategories, user]);

  return {
    getAllCategoryAndAllGames,
    getAllGamesByCategoryId,
    isLoading,
    totalItems,
    totalPages,
    error,
  };
};
