import { useState } from 'react';
import { AuthContext } from './authContext';
import { User } from 'types/user';
import { useStorage } from 'hooks/authHooks/useStorage';

export const AuthProvider = ({ children }: any) => {
  const { readUserData } = useStorage();
  const [user, setUserData] = useState<User | null>(readUserData());

  const setUser = (user: User | null) => {
    setUserData(user);
  };

  return <AuthContext.Provider value={{ user, setUser }}>{children}</AuthContext.Provider>;
};
