import LobbyIcon from 'assets/icons/menuIcons/lobby.svg';
import SlotsIcon from 'assets/icons/menuIcons/slots.svg';
import StatisticsIcon from 'assets/icons/menuIcons/statistics.svg';
import BonusesIcon from 'assets/icons/menuIcons/bonuses.svg';
import X_0 from 'assets/icons/menuIcons/x0.svg';

import BalanceIcon from 'assets/icons/menuIcons/balance.svg';
import favoriteIcon from 'assets/icons/menuIcons/favoriteIcon.svg';

import WheelIcon from 'assets/icons/menuIcons/wheel.svg';
import LiveGamesIcon from 'assets/icons/menuIcons/live.png';
import VipClubIcon from 'assets/icons/menuIcons/vipClub.svg';
import X_000 from 'assets/icons/menuIcons/x000.svg';
import NewGameIcon from 'assets/icons/newGames.svg';
import TopGameIcon from 'assets/icons/top.svg';
import TelegramIcon from 'assets/icons/menuIcons/telegram.svg'
import WalletIcon from 'assets/icons/menuIcons/wallet.svg'

export const Lobby = LobbyIcon;
export const Slots = SlotsIcon;
export const Statistics = StatisticsIcon;
export const Balance = BalanceIcon;
export const Games = favoriteIcon;
export const Bonuses = BonusesIcon;
export const Wheel = WheelIcon;
export const Deposit = X_0;
export const LiveGames = LiveGamesIcon;
export const VipClub = VipClubIcon;
export const Favorites = X_000;
export const NewGame = NewGameIcon;
export const TopGame = TopGameIcon;
export const Telegram = TelegramIcon
export const Wallet = WalletIcon