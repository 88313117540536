import { Box } from '@mui/material';
import aboutUsImg from 'assets/aboutUs/aboutUs.png';
import useAboutUs from 'hooks/aboutUs/useAboutUs';
import { useEffect, useState } from 'react';
import { useStorage } from 'hooks/authHooks/useStorage';
import { RawDraftContentState, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { useTranslation } from 'react-i18next';
import styles from './aboutUs.module.scss';

interface About {
  id: string;
  language: string;
  code: string;
  siteId: string;
  about: RawDraftContentState;
}

function AboutUs() {
  const { aboutUs } = useAboutUs();
  const { readStorageData } = useStorage();
  const { t } = useTranslation('common');
  const [htmlCode, setHtmlCode] = useState<string | null>(null);

  useEffect(() => {
    const aboutUsData: About = aboutUs.filter(
      (el: About) => el.code === readStorageData('siteLanguage'),
    )?.[0];

    if (aboutUsData && aboutUsData.about) {
      const contentState = convertFromRaw(aboutUsData.about);
      const html = stateToHTML(contentState);
      setHtmlCode(html);
    } else {
      setHtmlCode(null);
    }
  }, [aboutUs, readStorageData('siteLanguage')]);

  useEffect(() => {
    if (htmlCode) {
      setHtmlCode(htmlCode);
    }
  }, [htmlCode]);

  return (
    <Box className={styles.aboutUs_main}>
      <Box className={styles.box_area}>
        <div dangerouslySetInnerHTML={{ __html: htmlCode ? htmlCode : t('underConstr') }} />
      </Box>
      <Box className={styles.img_box}>
        <img src={aboutUsImg} width={'100%'} alt="aboutUS" />
      </Box>
    </Box>
  );
}

export default AboutUs;
