import { Stack, Box, Typography, Autocomplete, TextField } from '@mui/material';
import { useState, useEffect, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useGame } from 'hooks/playGames/useGame';
import { IGame } from 'types/game';
import { MODAL_TYPES } from 'types/modals';
import { FIELD_NAME } from 'types/login';
import { useModalContext } from 'context/modal/modalContext';
import { useAuthContext } from 'context/auth/authContext';
import styles from './menuMobile.module.scss';

interface SearchMobileProps {
  onCloseNavMenu: () => void;
}

export const SearchMobile = ({ onCloseNavMenu }: SearchMobileProps) => {
  const { t } = useTranslation('common');
  const { searchGame } = useGame();
  const { user } = useAuthContext();
  const [debouncedValue, setDebouncedValue] = useState<IGame | string>('');
  const [value, setValue] = useState<IGame | string>('');
  const { openModalWithData } = useModalContext();
  const [gameList, setGameList] = useState<IGame[]>([]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;

    e.stopPropagation();
    setValue(value);
    console.log(value, 'handleChange');
  };

  const handleOnSelect = (e: SyntheticEvent<Element, Event>, option: IGame | null) => {
    if (option) {
      const { id, providerId, image, name, isFavorite, isUnliked } = option;

      if (user) {
        openModalWithData(MODAL_TYPES.CHOOSE_BALANCE, {
          gameId: id,
          providerId: providerId,
          image,
          name,
          isLiked: isFavorite,
          isUnliked,
        });
      } else {
        openModalWithData(FIELD_NAME.LOGIN_FOR_PLAY_GAME, {
          gameId: id,
          providerId: providerId,
          image,
          name,
        });
      }

      return onCloseNavMenu();
    }
  };

  useEffect(() => {
    const delay = 500;
    const timeoutId = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [value]);

  useEffect(() => {
    const forPublicReguest = !user ? '/public?' : undefined;
    const query = '&pageSize=20';
    const searchData = async () => {
      const result = await searchGame(debouncedValue, query, forPublicReguest);
      if (result?.status === 200) {
        setGameList(result.data.games);
      }
    };

    if (debouncedValue) {
      searchData();
    }
  }, [debouncedValue]);

  return (
    <Box className={styles.top_mobile}>
      <Typography className={styles.anchor_title} textAlign="left" sx={{ mb: 4 }}>
        {t('search')}
      </Typography>
      <Stack>
        <Autocomplete
          id="search-game"
          noOptionsText={t('noResult')}
          fullWidth
          options={gameList}
          onChange={(e, option) => handleOnSelect(e, option)}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => (
            <Box
              key={option.id}
              component="li"
              sx={{ '& > img': { mr: 2, flexShrink: 0 }, svg: { color: 'red' } }}
              {...props}
            >
              <img width="20" src={option.image} alt="items" />
              {option.name}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('searchGame')}
              InputProps={{
                ...params.InputProps,
                type: 'search',
              }}
              onChange={(e) => handleChange(e)}
            />
          )}
        />
      </Stack>
    </Box>
  );
};
