import { Box, Button,  TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from './promo.module.scss';

function Promo() {
  const { t } = useTranslation('deposit');
  const { t: commonT } = useTranslation('common');

  const handleOnClick = () => {
    console.log("promo")
  };

  return (
    <Box className={styles.main_area}>
      <Box className={styles.main_title}>{t('promo')}</Box>
      <Box className={styles.promo_block}>
        <Box className={styles.promo}>
          <TextField
            size="small"
            fullWidth
            placeholder={t('placeholderPromo')}
            variant="outlined"
            sx={{ '& input::placeholder': { fontSize: '13px' } }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button sx={{ ml: 2, minWidth: '110px' }} variant="contained" onClick={handleOnClick}>
            {commonT('apply')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default Promo;
