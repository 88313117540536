import { Box } from '@mui/material';
import Tabs from 'components/tab/tabs';
import CollapseStatistics from './statisticsItem/collapseStatistics';
import { useTranslation } from 'react-i18next';
import styles from './statistics.module.scss';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const tabMenu = [
  // { id: '1', name: 'retro' },
  { id: '2', name: 'popular' },
  { id: '3', name: 'bigWinnings' },
  { id: '4', name: 'yesterdayBigWinnings' },
  { id: '5', name: 'disliked' },
];

function Statistics() {
  const { t } = useTranslation('statistics');
  const { state } = useLocation();
  const [activeTab, setActiveTab] = useState<string>();
  const { device } = useSelector<any>((state) => state.device) as {
    device: string;
  };

  useEffect(() => {
    if (state?.activeTab) {
      setActiveTab(state.activeTab);
    }
  }, [state]);

  return (
    <Box className={styles.content}>
      {device === 'Desktop' ? (
        <Box className={styles.desktop}>
          <Tabs
            translateFrom={'statistics'}
            tabMenu={tabMenu}
            title={t('gameStatistics')}
            activeTab={activeTab}
          />
        </Box>
      ) : (
        <Box className={styles.mobile}>
          <CollapseStatistics
            translateFrom={'statistics'}
            tabMenu={tabMenu}
            title={t('gameStatistics')}
            activeTab={activeTab}
          />
        </Box>
      )}
    </Box>
  );
}

export default Statistics;
