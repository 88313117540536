import { ModalState } from 'context/modal/modalContext';
import ModalContent from '../modalContent/modalContent';
import UserLevels from 'components/userLevels/userLevels';

interface LevelsModalProps {
  onClose?: (type: string) => void;
  props?: ModalState;
}

export interface ITabs {
  id: string;
  name: string;
}

export interface IChildren {
  component: React.ReactNode;
}

const tabMenu = [{ id: '1', name: 'levels' }];

function LevelsModal({ onClose, props }: LevelsModalProps) {
  const children = [{ component: <UserLevels /> }];

  return (
    <ModalContent
      onClose={onClose}
      props={props}
      tabContent={children}
      tabMenu={tabMenu}
      translateFrom={'levels'}
    />
  );
}

export default LevelsModal;
