import img1 from 'assets/bonuses/terms/1.webp';
import img2 from 'assets/bonuses/terms/2.webp';
import img3 from 'assets/bonuses/terms/3.webp';
import img4 from 'assets/bonuses/terms/4.webp';
import img5 from 'assets/bonuses/terms/5.webp';
import img6 from 'assets/bonuses/terms/6.webp';
import img7 from 'assets/bonuses/terms/7.webp';
import img8 from 'assets/bonuses/terms/8.webp';
import img9 from 'assets/bonuses/terms/9.webp';
import img10 from 'assets/bonuses/terms/10.webp';

export const termsList = [
  {
    title: 'колесо фортуны',
    img: img1,
    description: `Активные игроки в Магнит-Х  получают уникальную возможность каждый день выигрывать до 1000 рублей! Для того, чтобы получить доступ к колесу нужно достичь уровня "Мастер" или выше. Бонус нужно отыграть в двукратном размере , максимальная сумма вывода  25.000 руб.`,
  },
  {
    title: 'кеш бек',
    img: img2,
    description: `Каждый день в 09:00 по московскому времени сайт Магнит-Х начисляет кэшбэк в размере 3% - 8%  от проигранных средств в зависимости от уровня клиента. В случае проигрыша бонусных средств кэшбэк также начисляется. Игрок имеет право на снятие кэшбэка без удержания комиссии. Начисление кэшбэка происходит автоматически.`,
  },
  {
    title: 'телеграмм каннал',
    img: img3,
    description: `Подписывайтесь на Telegram <a href = "https://t.me/magnitxcasino"  target="_blank"> канал </a>, чтобы быть в курсе о новых акциях, специальных предложениях, вы получите все новости касаемые с сайтом.
     Игроки Магнит-Х так же могут участвовать в розыгрышах и выиграть ПРОМОКОД. Это одноразовый код, по которому игроку начисляются бездепозитные денежные средства. Не пропустите эксклюзивные предложения и акции для наших подписчиков!`
  },
  {
    title: 'соц. сети',
    img: img4,
    description: `Мы предлагаем бонусы за подписку на наши каналы в социальных сетях, включая  <a href = "https://t.me/magnitxcasino"  target="_blank"> Telegram </a>,  <a href = "https://vk.com/magnitxcasino"  target="_blank"> ВК каналы </a>.
     Размер бонуса зависит от уровня клиента и может варьироваться в зависимости от активности на этих платформах. Для того чтобы узнать точные условия и размеры бонусов, а также получить подробную информацию о 
     текущих акциях и предложениях, пожалуйста, свяжитесь с нашим <a href = "https://t.me/magnitxcom"  target="_blank"> менеджером </a>, который с радостью предоставит все необходимые разъяснения.`,
  },

  {
    title: 'промокод для новых клиентов',
    img: img5,
    description: ` Для игроков казино Магнит-X в ознакомительных целях выдается промокод. Это одноразовый код, по которому игроку начисляются бездепозитные денежные средства, бонус нужно отыграть в двукратном размере максимальный вывод 25.000 руб. ,
    остальная сумма будет списана и аннулирована. Средства, полученные через ПРОМОКОД, но не использованные в течение двух недель, будут аннулированы. ПРОМОКОД даем только по приглашению.`,
  },
  {
    title: 'мульти-промокод',
    img: img6,
    description: `Этот бонус предназначен для наших лояльных и постоянных игроков. Мульти-промокод можно получить исключительно через участие в розыгрыше, который регулярно проводится в нашем Telegram-канале. Чтобы активировать бонус, 
    необходимо выполнить условия отыгрыша: сумма бонуса должна быть отыграна вдвойне. Важно помнить, что максимальная сумма вывода, полученная 
    от использования бонуса, составляет 25.000 рублей. Для более подробной информации о правилах участия в розыгрыше, а также о процессах отыгрыша бонуса и выводах средств, вы можете обратиться к нашему 
    <a href = "https://t.me/magnitxcom"  target="_blank"> менеджеру </a>, который предоставит все необходимые разъяснения.`,
  },
  {
    title: 'Почта',
    img: img7,
    description: `Для того чтобы получать ежемесячный бонус в размере 100 рублей, отправьте ваш логин на электронную <a href = "mailto:magnitt-x@mail.ru"> почту </a>. В течение 24 часов наша администрация проверит запрос и зачислит 
    бонус на ваш аккаунт. Для получения подробной информации или если у вас возникнут дополнительные вопросы, не стесняйтесь обратиться к нашему  <a href = "https://t.me/magnitxcom"  target="_blank"> менеджеру </a>.
`,
  },
  {
    title: 'бонус на день рождения',
    img: img8,
    description: `В этот особенный день мы подготовили для вас эксклюзивный бонус – уникальный подарок, который подчеркнёт ваше особое положение среди наших игроков. Бонус предоставляется постоянным клиентам, успешно прошедшим верификацию аккаунта, 
    и его сумма зависит от вашего текущего уровня в системе, чтобы сделать сюрприз по-настоящему приятным. Для получения подарка в день рождения, просто свяжитесь с нашим  <a href = "https://t.me/magnitxcom"  target="_blank"> менеджером </a>, который предоставит все необходимые инструкции и поможет оформить бонус.`,
  },
  {
    title: 'Уровен',
    img: img9,
    description: `В казино Магнит-Х действует система уровней, благодаря которой вы можете получать все больше бонусов и привилегий. Чем чаще и на большую сумму вы пополняете свой счет, тем выше ваш уровень, а значит, 
    тем больше вознаграждений вы получаете в процессе игры. Каждый уровень открывает для вас новые возможности и дополнительные бонусы. Подробную информацию о всех уровнях, а также о связанных с ними привилегиях, вы найдете в разделе «Уровень».`,
  },
  {
    title: 'фриспины',
    img: img10,
    description: `Это уникальный бонус, который дает игрокам возможность бесплатно вращать барабаны в слотах, при этом сохраняя шанс на реальный выигрыш. Фриспины предоставляются только активным игрокам и требуют отыгрыша согласно установленным
     условиям. Максимальная сумма, которую можно вывести, составит 25.000 рублей. Окунитесь в азарт игры, наслаждайтесь шансом на бесплатные выигрыши и увеличивайте свой игровой баланс вместе с фриспинами от казино Магнит-Х!`,
  },
];