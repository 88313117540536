import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PaymentsTable from 'components/paymentsTable/paymentsTable';
import PaymentsItem from './paymentsItem/paymentsItem';
import { useEffect, useState } from 'react';
import { useTransaction } from 'hooks/transactions/useTransaction';
import styles from './payments.module.scss';

function Payments() {
  const { t } = useTranslation('payment');
  const [rowsPayIn, setRowsPayIn] = useState([]);
  const [rowsPayOut, setRowsPayOut] = useState([]);
  const { getAllPayInMethods, getAllPayOutMethods } = useTransaction();

  const getPayments = async () => {
    const payIn = await getAllPayInMethods('isCrypto=false');
    if (payIn?.status === 200) {
      setRowsPayIn(payIn.data);
    }
    const payOut = await getAllPayOutMethods('isCrypto=false');
    if (payOut?.status === 200) {
      setRowsPayOut(payOut.data);
    }
  };

  useEffect(() => {
    getPayments();
  }, []);

  return (
    <Box className={styles.content}>
      <Box className={styles.main_info}>
        <Box className={styles.titles_info}>
          <Box>
            <Typography variant="h4">{t('title')}</Typography>
            <Typography component={'p'} sx={{ maxWidth: '500px' }}>
              {t('subTitle')}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={styles.desktop}>
        <PaymentsTable translation="payment" rowsPayIn={rowsPayIn} rowsPayOut={rowsPayOut} />
      </Box>
      <Box className={styles.mobile}>
        <PaymentsItem translaste={'payment'} rowsPayIn={rowsPayIn} rowsPayOut={rowsPayOut} />
      </Box>
    </Box>
  );
}

export default Payments;
