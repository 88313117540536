import Alert, { AlertColor } from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Stack } from '@mui/material';
import { useEffect, useState } from 'react';

interface CustomAlertProps {
  isShow: boolean;
  style?: object;
  hasAction?: boolean;
  type: AlertColor;
  message: string;
  onClose?: () => void;
}

export default function CustomAlert({
  isShow,
  style,
  type,
  message,
  hasAction,
  onClose,
}: CustomAlertProps) {
  const [open, setOpen] = useState(isShow);

  useEffect(() => {
    setOpen(isShow);
  }, [isShow]);

  return (
    <>
      {open && (
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert
            sx={{ ...style }}
            variant="filled"
            severity={type}
            action={
              hasAction ? (
                <IconButton
                  aria-label="close"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                    onClose?.();
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              ) : null
            }
          >
            {message}
          </Alert>
        </Stack>
      )}
    </>
  );
}
