import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  setConfigs,
  setCurrencyList,
} from 'redux/actions/siteConfigurationActions';
import SiteService from 'services/site.service';

export const useSiteConfig = (needForce = false) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const getSiteConfig: any = async () => {
    try {
      const {
        data: { data },
      } = await SiteService.getSiteConfiguration();
      dispatch(setCurrencyList(data.currencies));
      dispatch(setConfigs(data));
      setIsLoading(false);
    } catch (error: any) {
     /**TODO show global ErrorBoundary component */
      console.log('LOG____>', error);
    }
  };

  useEffect(() => {
    if (needForce) {
      getSiteConfig();
    }
  }, [needForce]);

  return {
    isLoading,
    getSiteConfig,
  };
};
