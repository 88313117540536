import { createTheme } from '@mui/material';
import grey from '@mui/material/colors/grey';

export const light = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 900,
      lg: 1180,
      xl: 1920,
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#465063',
    },
    background: {
      default: '#465063',
      paper: '#1c2030',
    },
    text: {
      primary: '#FFF',
      // primary: '#2a2e5c',
      secondary: grey[200],
    },
  },
  typography: {
    allVariants: {
      fontFamily: 'system-ui',
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'contained' && {
            backgroundColor: '#de5000',
            color: '#c0e1fc',
          }),
          '&:hover': { color: '#fff' },
          '&.MuiButtonBase-root': { borderRadius: '14px' },
        }),
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: () => ({
          backgroundColor: '#c0e1fc',
          svg: { color: '#3e4168' },
          color: '#3e4168',
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: () => ({
          ul: {
            paddingTop: 0,
          },
        }),
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: () => ({
          color: '#c0e1fc',
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: () => ({
          input: {
            textIndent: 4,
            borderColor: '#fff',
          },
          fieldset: {
            borderColor: '#fff',
          },
        }),
      },
    },
    // MuiTypography: {
    //   styleOverrides: {
    //     root: ({ ownerState }) => ({
    //       ...(ownerState.variant === 'h5' && {
    //         color: '#c0e1fc',
    //       }),
    //       ...(ownerState.variant === 'h6' && {
    //         color: '#2a2e5c',
    //       }),
    //     }),
    //   },
    // },
    MuiLink: {
      styleOverrides: {
        root: () => ({
          color: '#de5000',
          fontWeight: 600,
        }),
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: () => ({
          '&.Mui-focused': { color: '#de5000' },
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: () => ({
          span: { fontSize: '0.8rem' },
        }),
      },
    },
  },
});
