import http from '../http-common';
import { SITE_ID } from 'services/site.service';

class TermsDataService {
  getSiteTerms = (headers) => http.get(`/site/${SITE_ID}/terms`, { headers });
}

const TermsService = new TermsDataService();

export default TermsService;
