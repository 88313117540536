import { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import cx from 'classnames';
import { useUserData } from 'hooks/user/useUserData';
import { useTranslation } from 'react-i18next';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LoaderWithBg from 'components/loader/loader';
import { common, dialog } from 'constants/common';
import { useBonus } from 'hooks/user/useBonus';
import Dialogs from 'components/dialog/dialogs';
import Result from 'components/alert/alert';
import { RESULT } from 'types/common';
import { IBonus } from 'types/bonus';
import styles from './bonuses.module.scss';

function Bonuses() {
  const { getUserBonuses, bonusData, isLoading } = useUserData();
  const { applyBonus, cancelBonus, isLoading: bonusLoading, error, resetError } = useBonus();
  const { t } = useTranslation('deposit');
  const { t: commonT } = useTranslation('common');
  const [activeBonus, setActiveBonus] = useState<IBonus | null>(null);
  const [assumeActiveBonusId, setAssumeActiveBonusId] = useState<string>('');
  const [bonusList, setBonusList] = useState<IBonus[]>([]);
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [open, setOpen] = useState(false);
  let timeOutId: ReturnType<typeof setTimeout>;

  const cancelOrApplyBonus = async (bonusId: number, type: string) => {
    const activeBonusItem =
      type === common.APPLY ? await applyBonus(bonusId) : await cancelBonus(bonusId);
    const filteredItem = bonusData?.filter((el: any) => el.id === activeBonusItem?.data?.id);

    if (filteredItem?.length) {
      filteredItem[0].isActive = activeBonusItem?.data?.isActive;
    }
    setBonusList([...bonusData]);
    setActiveBonus(type === common.APPLY ? activeBonusItem?.data : null);
  };

  const handleOnConfirm = async (id: string) => {
    const canceledItem = await cancelBonus(activeBonus?.id);
    if (canceledItem?.data) {
      const filteredItem = bonusList?.filter((el: IBonus) => el.id === activeBonus?.id);
      if (filteredItem?.length) {
        filteredItem[0].isActive = canceledItem?.data?.isActive;
      }
      const appliedItem = await applyBonus(id);
      if (appliedItem?.data) {
        const filteredItem = bonusList?.filter((el: IBonus) => el.id === +id);
        if (filteredItem?.length) {
          filteredItem[0].isActive = appliedItem?.data?.isActive;
          setBonusList([...bonusList]);
          setActiveBonus({ ...filteredItem[0] });
        }
        setStatusMessage(commonT(RESULT.SUCCESS));
      }
    } else {
      setStatusMessage(commonT('somethingWentWrong'));
    }
    timeOutId = setTimeout(() => {
      setStatusMessage('');
      if (error) resetError();
    }, 3000);
  };

  const handleOnClick = (type: string, bonusId: number) => {
    if (type === common.APPLY) {
      if (!activeBonus) {
        cancelOrApplyBonus(bonusId, type);
      } else {
        setOpen(true);
        setAssumeActiveBonusId(bonusId + '');
      }
    } else {
      if (bonusId === activeBonus?.id) cancelOrApplyBonus(bonusId, type);
    }
  };

  useEffect(() => {
    getUserBonuses();
    return () => {
      clearTimeout(timeOutId);
    };
  }, []);

  useEffect(() => {
    setBonusList(bonusData);
    const activeBonusItem = bonusData?.filter((el: any) => el.isActive)?.[0] ?? null;
    setActiveBonus(activeBonusItem);
  }, [bonusData]);

  return (
    <Box className={styles.main_area}>
      <Box className={styles.main_title}>{t('bonus')}</Box>

      {!activeBonus && bonusList.length ? (
        <Box className={styles.main_bonus_info}>
          <Typography variant="subtitle1" component="p">
            {t('userBonusInfo')}
          </Typography>
        </Box>
      ) : null}

      <Box className={styles.bonuses_area}>
        <Result
          style={{ mt: 2 }}
          message={statusMessage || error}
          type={error ? RESULT.ERROR : RESULT.SUCCESS}
          isShow={!!(statusMessage || error)}
        />

        {bonusList.length ? (
          bonusList.map((elm: any, i: number) => {
            return (
              <Box key={i} className={cx(styles.bonus_info, { [styles.isActive]: elm.isActive })}>
                <Box className={styles.bonus_title_area}>
                  <Box sx={{ flexDirection: 'column', minWidth: '150px' }}>
                    <Box>
                      {elm.isActive && (
                        <CheckCircleIcon sx={{ color: 'green', fontSize: '18px' }} />
                      )}
                      <Typography
                        component={'span'}
                        fontSize={'14px'}
                        color={elm.isActive ? 'green' : 'red'}
                      >
                        {elm.isActive && commonT('bonusIsActive')}
                      </Typography>
                    </Box>
                    <Typography sx={{ fontSize: '1.2rem', maxWidth: '250px' , width:'auto'}} variant={'h4'}>
                      {elm.name}
                    </Typography>
                  </Box>
                  <Typography component={'span'} fontSize={'11px'}>
                    {elm.comment}
                  </Typography>
                </Box>
                <Box className={styles.info_amount}>
                  <Box className={styles.item}>
                    <Typography component={'span'} sx={{fontWeight:'bolder'}}>{commonT('amount')}: </Typography>
                    <Typography component={'span'} >{elm.amount}</Typography>
                  </Box>
                  <Box className={styles.item}>
                    <Typography component={'span'}  sx={{fontWeight:'bolder', color: 'red'}}>{commonT('weiger')}: </Typography>
                    <Typography component={'span'} > × {elm.weiger}</Typography>
                  </Box>
                </Box>
                <Box className={styles.button_area}>
                  <Button
                    sx={{ backgroundColor: elm.isActive ? 'gray' : 'green' }}
                    disabled={elm.isActive}
                    onClick={() => handleOnClick(common.APPLY, elm.id)}
                  >
                    {commonT('apply')}
                  </Button>
                  <Button
                    disabled={!elm.isActive}
                    sx={{
                      backgroundColor: !elm.isActive && activeBonus ? 'gray' : 'red',
                    }}
                    onClick={() => handleOnClick(common.CANCEL, elm.id)}
                  >
                    {commonT('refuse')}
                  </Button>
                </Box>
              </Box>
            );
          })
        ) : (
          <Box className={styles.haveNoBonus_area}>
            <Box className={styles.haveNoBonus_box}>
              <DescriptionIcon />
              <Typography fontSize={'12px'}>{t('haveNoBonuses')}</Typography>
            </Box>
          </Box>
        )}

        <Box sx={{ color: 'black' }}>
          <Dialogs
            id={assumeActiveBonusId}
            title={commonT(dialog.CANCEL_BONUS)}
            content={commonT(dialog.CANCEL_BONUS_MESSAGE)}
            isOpen={open}
            onClose={() => setOpen(false)}
            onConfirm={handleOnConfirm}
            btnText={commonT('yes')}
            ignoreBtnText={commonT('no')}
            contentStyle={{ padding: '0 12px' }}
          />
        </Box>
      </Box>
      {bonusLoading || isLoading ? <LoaderWithBg isOpen={bonusLoading || isLoading} /> : null}
    </Box>
  );
}

export default Bonuses;
