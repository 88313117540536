import { useStorage } from 'hooks/authHooks/useStorage';
import { useApiErrors } from 'hooks/errors/useApiErrors';
import { useUser } from 'hooks/user/useUser';
import { useEffect, useState } from 'react';
import SiteAboutUsService from 'services/aboutUs.service';
import { generateHeaders } from 'utils/utils';

const useAboutUs = () => {
  const [error, setError] = useState('');
  const [aboutUs, setAboutUs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { userToken } = useStorage();
  const { removeUser } = useUser();
  const { errorHandler } = useApiErrors();
  const initHeaders = generateHeaders(userToken());

  const getAboutUsForSite: any = async (headers = initHeaders) => {
    setIsLoading(true);
    try {
      const {
        data: { data, status },
      } = await SiteAboutUsService.getSiteAboutUs(headers);
      if (status === 200) {
        setIsLoading(false);
        setAboutUs(data);
      }
    } catch (err: any) {
      if (err.response) {
        const {
          data: { message, status },
        } = err.response;
        const result = await errorHandler(status, message);
        const { newHeader, needLogout } = result ?? {};
        if (needLogout) {
          removeUser();
        } else if (!needLogout && newHeader) {
          return await getAboutUsForSite(headers);
        } else {
          console.log('LOG____> playGame', message);
          setError(message);
        }
      }
    }
  };

  useEffect(() => {
    getAboutUsForSite();
  }, []);

  return {
    isLoading,
    aboutUs,
    getAboutUsForSite,
    error,
  };
};

export default useAboutUs;
