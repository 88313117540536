import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import styles from './tooltip.module.scss';

export interface IContent {
  name: string;
  link: string;
}

interface TooltipComponentProps {
  btnText: string;
  tooltipContent?: IContent[];
  wrapClass: string;
}

export default function TooltipComponent({
  btnText,
  tooltipContent,
  wrapClass,
}: TooltipComponentProps) {
  const [openTooltip, setOpenTooltipValue] = useState(false);
  let timeoutId: ReturnType<typeof setTimeout>;

  const toggleClick = () => {
    setOpenTooltipValue((prev) => !prev);
  };

  useEffect(() => {
    if (openTooltip) {
      const delay = 4000;
      timeoutId = setTimeout(() => {
        setOpenTooltipValue(false);
      }, delay);
    }

    return () => clearTimeout(timeoutId);
  }, [openTooltip]);

  return (
    <Tooltip
      className={styles.tool_tip}
      onClose={toggleClick}
      open={openTooltip}
      title={tooltipContent?.map((el, index) => {
        return (
          <Link key={index} to={el.link} target="blank">
            {el.name}
          </Link>
        );
      })}
      arrow
      PopperProps={{
        disablePortal: true,
      }}
      disableFocusListener
      disableHoverListener
    >
      <Button className={wrapClass} onClick={toggleClick}>
        {btnText}
      </Button>
    </Tooltip>
  );
}
