import { Box, Button, FormControl, Input, InputLabel, Typography } from '@mui/material';
import VerificationImagesUpload from '../verificationImagesUpload/verificationImagesUpload';
import { ChangeEvent, useState } from 'react';
import cx from 'classnames';
import useVerification from 'hooks/user/useVerification';
import VerificationStatusInfo from '../verificationStatusInfo/verificationStatusInfo';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LoaderWithBg from 'components/loader/loader';
import { FIELD_NAME_USER_INFO } from 'types/user';
import { isValidValueForUserInfo } from 'utils/helpers/inputHelper';
import PassportImage from 'assets/verification/passport.png';
import SelfyImage from 'assets/verification/selfy.png';
import styles from './verification.module.scss';

const initialData = { frontView: '', rearView: '' };

const field = {
  [FIELD_NAME_USER_INFO.PHONE]: true,
  [FIELD_NAME_USER_INFO.EMAIL]: true,
};

function Verification() {
  const { t: profileT } = useTranslation('profile');
  const { t: valitdationT } = useTranslation('validation');
  const [formData, setFormData] = useState(initialData);
  const userData = useSelector<any>((state) => state.userData) as any;
  const [emailInputStatus, setEmailInputStatus] = useState<string | null>(null);
  const [phoneInputStatus, setPhoneInputStatus] = useState<string | null>(null);
  const [validField, setValidField] = useState(field);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(userData.phone ?? null);
  const [emailAddress, setEmailAddress] = useState<string | null>(userData.email ?? null);
  const [status, setStatus] = useState<null | number>(null);
  const { sendVerificationData, isLoading } = useVerification();

  const onImageUpload = (url: any, keys: string) => {
    keys === 'without-you'
      ? setFormData({ ...formData, frontView: url })
      : setFormData({ ...formData, rearView: url });
  };

  const changePhoneStatus = (name?: string) => {
    name
      ? setPhoneInputStatus(name)
      : (setPhoneInputStatus(null),
        setPhoneNumber(userData.phone),
        setValidField((validField) => ({
          ...validField,
          [FIELD_NAME_USER_INFO.PHONE]: true,
        })));
  };

  const changeEmailAddresStatus = (name?: string) => {
    name
      ? setEmailInputStatus(name)
      : (setEmailInputStatus(null),
        setEmailAddress(userData.email),
        setValidField((validField) => ({
          ...validField,
          [FIELD_NAME_USER_INFO.EMAIL]: true,
        })));
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, name: string) => {
    const { value } = e.target;
    setValidField((validField) => ({
      ...validField,
      [name]: !!isValidValueForUserInfo(value, name),
    }));
    name === FIELD_NAME_USER_INFO.PHONE ? setPhoneNumber(value) : setEmailAddress(value);
  };

  const handleSave = async () => {
    const uploadData = new FormData();

    const appendFile = async (field: any, url: any) => {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const file = new File([blob], url.split('/').pop(), { type: blob.type });
        uploadData.append(field, file);
      } catch (error) {
        console.error(error);
      }
    };

    await appendFile('frontView', formData.frontView);
    await appendFile('rearView', formData.rearView);

    const { status } = await sendVerificationData(uploadData);
    setStatus(status);
  };

  return (
    <Box className={styles.generalArea}>
      <Box className={styles.main_title}>{profileT('verification')}</Box>
      <Box className={styles.phoneOrEmailConfirmation}>
        {phoneInputStatus === 'changePhoneNumber' ? (
          <Box mt={2}>
            <Typography fontSize={18} mb={2}>
              {profileT('changePhoneNumber')}
            </Typography>
            <FormControl
              className={cx(styles.form_control, {
                [styles.is_error]: !validField[FIELD_NAME_USER_INFO.PHONE],
              })}
            >
              <InputLabel shrink htmlFor="email-input">
                {validField[FIELD_NAME_USER_INFO.PHONE] ? (
                  profileT('phoneNumber')
                ) : (
                  <Typography>{valitdationT('invalidPhoneNumber')}</Typography>
                )}
              </InputLabel>
              <Input
                className={styles.custom_input}
                placeholder={profileT('fillPhone')}
                fullWidth
                id="lastName-input"
                value={phoneNumber ?? userData.phone}
                onChange={(e) => handleOnChange(e, FIELD_NAME_USER_INFO.PHONE)}
              />
            </FormControl>
            <Box className={styles.buttons_box}>
              <Button
                variant="contained"
                onClick={() => changePhoneStatus()}
                className={styles.with_color}
              >
                {profileT('cancel')}
              </Button>
              <Button
                variant="contained"
                disabled={
                  (userData.isPhoneConfirmed && userData.phone === phoneNumber) ||
                  !validField[FIELD_NAME_USER_INFO.PHONE]
                }
                onClick={() => changePhoneStatus('confirmationPhoneNumber')}
              >
                {profileT('confirm')}
              </Button>
            </Box>
          </Box>
        ) : phoneInputStatus === 'confirmationPhoneNumber' ? (
          <Box mt={2}>
            <Typography fontSize={15}>{profileT('confirmationPhone')}</Typography>
            <Typography component={'span'} color={'grey'} fontSize={13}>
              {profileT('codeWasSend')}
              {phoneNumber} (
              <Typography
                component={'span'}
                sx={{ fontSize: 12, color: 'orange', cursor: 'pointer' }}
                onClick={() => changePhoneStatus('changePhoneNumber')}
              >
                {profileT('changeNumber')}
              </Typography>
              )
            </Typography>
            <FormControl className={styles.form_control}>
              <Input
                className={styles.custom_input}
                placeholder={profileT('fillCode')}
                fullWidth
                id="lastName-input"
              />
            </FormControl>
            <Box className={styles.buttons_box}>
              <Button
                className={styles.with_color}
                variant="contained"
                onClick={() => changePhoneStatus()}
              >
                {profileT('cancel')}
              </Button>
              <Button
                variant="contained"
                onClick={() => changePhoneStatus('confirmationPhoneNumber')}
              >
                {profileT('confirm')}
              </Button>
            </Box>
          </Box>
        ) : !userData.phone ? (
          <Box m={3} className={styles.without_phone}>
            <Typography fontSize={15}>{profileT('phoneNumber')}</Typography>
            <Typography component={'span'} color={'grey'} fontSize={13}>
              {profileT('phoneNumberIsNotHave')}
            </Typography>
            <Button variant="contained" onClick={() => changePhoneStatus('changePhoneNumber')}>
              {profileT('addPhoneNumber')}
            </Button>
          </Box>
        ) : (
          <Box mt={2}>
            <Box className={styles.title}>
              <Typography fontSize={15}>{profileT('phoneNumber')}</Typography>
              <VerificationStatusInfo phoneConfirmedStatus={userData.isPhoneConfirmed} />
            </Box>
            <Typography component={'span'} color={'grey'} fontSize={13}>
              {userData.phone}
            </Typography>
            <Box className={styles.buttons_box}>
              <Button
                variant="contained"
                onClick={() => changePhoneStatus('changePhoneNumber')}
                className={styles.with_color}
              >
                {profileT('changeNumber')}
              </Button>
              <Button
                disabled={userData.isPhoneConfirmed}
                variant="contained"
                onClick={() => changePhoneStatus('confirmationPhoneNumber')}
              >
                {profileT('confirmPhoneNumber')}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      <Box className={styles.phoneOrEmailConfirmation}>
        {emailInputStatus === 'changeEmailAddress' ? (
          <Box mt={2}>
            <Typography fontSize={14} mb={2}>
              {profileT('changingEmailAddress')}
            </Typography>
            <FormControl
              className={cx(styles.form_control, {
                [styles.is_error]: !validField[FIELD_NAME_USER_INFO.EMAIL],
              })}
            >
              <InputLabel shrink htmlFor="email-input">
                {validField[FIELD_NAME_USER_INFO.EMAIL] ? (
                  profileT('emailAddress')
                ) : (
                  <Typography>{valitdationT('invalidEmail')}</Typography>
                )}
              </InputLabel>
              <Input
                className={styles.custom_input}
                placeholder={profileT('fillEmail')}
                fullWidth
                id="email-input"
                value={emailAddress ?? userData.email}
                onChange={(e) => handleOnChange(e, FIELD_NAME_USER_INFO.EMAIL)}
              />
            </FormControl>
            <Box className={styles.buttons_box}>
              <Button
                variant="contained"
                onClick={() => changeEmailAddresStatus()}
                className={styles.with_color}
              >
                {profileT('cancel')}
              </Button>
              <Button
                variant="contained"
                disabled={
                  (userData.isEmailConfirmed && userData.email === emailAddress) ||
                  !validField[FIELD_NAME_USER_INFO.EMAIL]
                }
                onClick={() => changeEmailAddresStatus('confirmationEmailAddress')}
              >
                {profileT('confirm')}
              </Button>
            </Box>
          </Box>
        ) : emailInputStatus === 'confirmationEmailAddress' ? (
          <Box mt={2}>
            <Typography fontSize={14}>{profileT('confirmationEmailAddress')}</Typography>
            <Typography component={'span'} color={'grey'} fontSize={13}>
              {profileT('emailCodeWasSend')}
            </Typography>
            <FormControl className={styles.form_control}>
              <Input
                className={styles.custom_input}
                placeholder={profileT('fillCode')}
                fullWidth
                id="lastName-input"
              />
            </FormControl>
            <Box className={styles.buttons_box}>
              <Button
                className={styles.with_color}
                variant="contained"
                onClick={() => changeEmailAddresStatus()}
              >
                {profileT('cancel')}
              </Button>
              <Button
                variant="contained"
                onClick={() => changePhoneStatus('confirmationPhoneNumber')}
              >
                {profileT('confirm')}
              </Button>
            </Box>
          </Box>
        ) : (
          <Box mt={2}>
            <Box className={styles.title}>
              <Typography fontSize={14}>{profileT('emailAddress')}</Typography>
              <VerificationStatusInfo emailConfirmedStatus={userData.isEmailConfirmed} />
            </Box>
            <Typography component={'span'} color={'grey'} fontSize={13}>
              {userData.email}
            </Typography>
            <Box className={styles.buttons_box}>
              <Button
                variant="contained"
                onClick={() => changeEmailAddresStatus('changeEmailAddress')}
                className={styles.with_color}
              >
                {profileT('changeEmailAddress')}
              </Button>
              <Button
                disabled={userData.isEmailConfirmed}
                variant="contained"
                onClick={() => changeEmailAddresStatus('confirmationEmailAddress')}
              >
                {profileT('confirmEmailAddress')}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      <Box className={styles.mainArea}>
        <Box className={styles.passportVerificationArea}>
          <VerificationStatusInfo
            verificationStatus={userData?.verificationStatus}
            rejectedReason={
              userData?.verificationStatus === 'rejected' ? userData?.unverifiedReason : null
            }
          />
          <Box className={styles.passportVerificationBox}>
            {isLoading ? <LoaderWithBg isOpen={isLoading} /> : null}
            <VerificationImagesUpload
              image={PassportImage}
              title="mainDocument"
              text="textForDocument"
              keys="without-you"
              status={status}
              onImageUpload={onImageUpload}
            />
            <VerificationImagesUpload
              image={SelfyImage}
              title="photoWithDocument"
              text="textForPhotoWithDociment"
              keys="with-you"
              status={status}
              onImageUpload={onImageUpload}
            />
          </Box>
          <Button
            variant="contained"
            disabled={
              formData.frontView === '' ||
              formData.rearView === '' ||
              userData?.verificationStatus === 'verified' ||
              userData?.verificationStatus === 'inProcess' ||
              isLoading
            }
            onClick={handleSave}
            sx={{ m: 2, color: 'white' }}
          >
            {profileT('upload')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default Verification;
