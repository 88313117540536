import Deposit from 'components/deposit/deposit';
import Withdraw from 'components/withdraw/withdraw';
import History from 'components/history/histrory';
import { ModalState } from 'context/modal/modalContext';
import BalanceTransfer from 'components/balanceTransfer/balanceTransfer';
import ModalContent from '../modalContent/modalContent';
import { useSelector } from 'react-redux';
import { useSiteConfig } from 'hooks/configHooks/useConfig';
import Promo from 'components/promo/promo';
import Bonuses from 'components/bonuses/bonuses';

interface DepositModalProps {
  onClose?: (type: string) => void;
  props?: ModalState;
}

export interface ITabs {
  id: string;
  name: string;
}

export interface IChildren {
  component: React.ReactNode;
}

const tabMenu = [
  { id: '1', name: 'deposit' },
  { id: '2', name: 'withdraw' },
  { id: '3', name: 'bonus' },
];

const generateTabs = (dataObj: { [x: string]: boolean }) => {
  let tabs = [] as ITabs[];
  for (const key in dataObj) {
    tabs = [...tabs, ...tabMenu.filter((el) => el.name === key && dataObj[key])];
  }
  return tabs;
};

function DepositModal({ onClose, props }: DepositModalProps) {
  useSiteConfig(true);

  const { settings } = useSelector<any>((state) => state.siteConfigs) as { [x: string]: any };
  const hasHistory =
    (settings && settings?.isBonusesVisible) ||
    settings?.isPayinsVisible ||
    settings?.isPayoutsVisible;

  const historyTabMenu = hasHistory
    ? [
        ...generateTabs({
          bonus: settings?.isBonusesVisible,
          deposit: settings?.isPayinsVisible,
          withdraw: settings?.isPayoutsVisible,
        }),
        { id: '4', name: 'transfer' },
      ]
    : [];

  const children = [
    { component: <BalanceTransfer /> },
    { component: <Deposit /> },
    { component: <Withdraw /> },
    { component: <Bonuses /> },
    { component: <Promo /> },
  ];

  const tabMenu = [
    { id: '1', name: 'balanceTransfer' },
    { id: '2', name: 'deposit' },
    { id: '3', name: 'withdraw' },
    { id: '4', name: 'bonus' },
    { id: '5', name: 'promo' },
  ];

  if (hasHistory) {
    children.push({ component: <History historyTabMenu={historyTabMenu} /> });
    tabMenu.push({ id: `${tabMenu.length + 1}`, name: 'history' });
  }

  return (
    <ModalContent
      onClose={onClose}
      props={props}
      tabContent={children}
      tabMenu={tabMenu}
      translateFrom={'deposit'}
    />
  );
}

export default DepositModal;
