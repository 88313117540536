import { Box, Tab } from '@mui/material';
import { useEffect, useState } from 'react';
import cx from 'classnames';
import TabPanel from '@mui/lab/TabPanel';
import Level from 'components/level/level';
import { useTranslation } from 'react-i18next';
import { TabContext, TabList } from '@mui/lab';
import { ModalState } from 'context/modal/modalContext';
import { TABLET } from 'components/sections/topSection';
import { IChildren, ITabs } from '../depositModal/depositModal';
import styles from './modalContent.module.scss';

interface ModalContentProps {
  onClose?: (type: string) => void;
  props?: ModalState;
  tabMenu?: ITabs[];
  tabContent?: IChildren[];
  translateFrom: string;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function ModalContent({ props, tabMenu, tabContent, translateFrom }: ModalContentProps) {
  const { t } = useTranslation(`${translateFrom}`);
  const [isMobile, setIsMobile] = useState(false);
  const [value, setValue] = useState(props?.data?.activeTab ?? '0');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  function handleWindowResizing() {
    setIsMobile(window.innerWidth <= TABLET);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowResizing);
    setIsMobile(window.innerWidth <= TABLET);
    return () => {
      window.removeEventListener('resize', handleWindowResizing);
    };
  }, []);

  return (
    <Box
      className={cx(styles.container, {
        [styles.isMobile]: isMobile,
      })}
    >
      <TabContext value={value}>
        <Box
          className={cx(styles.leftSide, {
            [styles.isMobile]: isMobile,
          })}
        >
          <Level className={styles.modal_level} variant="buffer" />
          <Box className={styles.tabs} sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              variant="scrollable"
              scrollButtons={isMobile}
              allowScrollButtonsMobile={isMobile}
              onChange={handleChange}
              orientation={isMobile ? 'horizontal' : 'vertical'}
              sx={{
                '.MuiTabs-flexContainer': {
                  // flexWrap: 'wrap',
                  width: '90vw',
                },
              }}
            >
              {tabMenu?.map(({ name }, index) => {
                return (
                  <Tab
                    key={index}
                    value={index + ''}
                    label={t(name)}
                    {...a11yProps(0)}
                    sx={{ alignItems: 'start' }}
                  />
                );
              })}
            </TabList>
          </Box>
        </Box>
        <Box
          className={cx(styles.rightSide, {
            [styles.isMobile]: isMobile,
          })}
        >
          {tabContent?.map(({ component }, index) => {
            return (
              <Box key={index}>
                <TabPanel value={index + ''}>{component}</TabPanel>
              </Box>
            );
          })}
        </Box>
      </TabContext>
    </Box>
  );
}

export default ModalContent;
