import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import moment from 'moment';
import styles from './notificationItem.module.scss';

interface NotificationItemProps {
  /**TODO: write real types after getting all final data from BE */
  item: any;
  index: number;
  wrapperClassName?: string;
}

function NotificationItem({ item, wrapperClassName, index }: NotificationItemProps) {
  const { t } = useTranslation('common');

  return (
    <Box className={cx(styles.notifications_item, wrapperClassName)} key={index}>
      <Box className={styles.content}>
        <Box>
          <Typography>{moment(item.createdAt).format('DD/MM/Y HH:mm:ss')}</Typography>
        </Box>
        <Box
          className={cx({
            [styles.new]: !item.isViewed,
            [styles.read]: item.isViewed,
          })}
        >
          {item.isViewed ? t('read') : t('new')}
        </Box>
      </Box>
      <Box className={styles.text}>
        <Typography>{item.message}</Typography>
      </Box>
    </Box>
  );
}

export default NotificationItem;
