import { actionTypes } from 'redux/constants/actionTypes';

export const setCurrencyList = (currency) => {
  return {
    type: actionTypes.SET_CURRENCY_DATA,
    payload: currency,
  };
};

export const setConfigs = (currency) => {
  return {
    type: actionTypes.SET_CONFIGS,
    payload: currency,
  };
};

export const setLanguagesList = (languages) => {
  return {
    type: actionTypes.SET_LANGUAGES_DATA,
    payload: languages,
  };
};
