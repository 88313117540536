import { Box, Typography } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import LoaderWithBg from 'components/loader/loader';
import { useTranslation } from 'react-i18next';
import { useUserData } from 'hooks/user/useUserData';
import WinnersCard from 'components/winnersCard/winnersCard';
import { useSelector } from 'react-redux';
import styles from './gameHistroy.module.scss';
import moment from 'moment';

const titles = ['gameName', 'startedAt', ['walletType', 'startAmount', 'endAmount'], 'game'];

function GameHistory() {
  const { t } = useTranslation('common');
  const [gameHistoryList, setGameHistoryList] = useState([]);
  const { isLoading, getUsersRecentGames } = useUserData();
  const userData = useSelector<any>((state) => state.userData) as any;

  useEffect(() => {
    const getData = async () => {
      const forModal = true;
      const { data } = await getUsersRecentGames(forModal);

      setGameHistoryList(data);
    };
    getData();
  }, []);

  return (
    <Box className={styles.general_area}>
      <Box className={styles.content}>
        <Box className={styles.item_list_title}>
          {titles.map((el: any, index) => {
            if (typeof el === 'string') {
              return (
                <Typography
                  sx={{ fontSize: '12px', width: '120px', textAlign: 'center' }}
                  key={index}
                  component={'span'}
                >
                  {t(el)}
                </Typography>
              );
            } else {
              return (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  key={index}
                >
                  {el.map((el1: string, i: number) => {
                    return (
                      <Typography
                        sx={{ fontSize: '12px', textAlign: 'center' }}
                        key={i}
                        component={'span'}
                      >
                        {t(el1)}
                      </Typography>
                    );
                  })}
                </Box>
              );
            }
          })}
        </Box>
        {isLoading ? (
          <LoaderWithBg isOpen={isLoading} />
        ) : (
          <Box className = {styles.content_list}>
            {gameHistoryList.map((elm: any, i) => {
              return (
                <Fragment key={i}>
                  <Box className={styles.item_list}>
                    <Box className={styles.item} sx={{ flexBasis: '100%' }}>
                      <Typography className={styles.name} component={'span'}>
                        {elm.gameName}
                      </Typography>
                      <Typography className={styles.name} component={'span'}>
                        {`${moment(elm.startedAt).format('DD/MM/Y HH:mm:ss')}`}
                      </Typography>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography className={styles.wallet} component={'span'}>
                          {elm.balance}
                        </Typography>
                        <Typography className={styles.amount} component={'span'}>
                          {elm.startAmount} {userData?.currency?.code}
                        </Typography>
                        <Typography className={styles.amount} component={'span'}>
                          {elm.endAmount} {userData?.currency?.code}
                        </Typography>
                      </Box>

                      <Box className={styles.play_game_box}>
                        <WinnersCard data={elm} />
                      </Box>
                    </Box>
                  </Box>
                  <Box className={styles.divider} />
                </Fragment>
              );
            })}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default GameHistory;
