import { Box, Button, IconButton, InputBase, Paper, Stack, Typography } from '@mui/material';
import GameCard from 'components/gameCard/gameCard';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import { IGame } from 'types/game';
import { makeStyles } from '@mui/styles';
import color from 'styles/colors.module.scss';
import { ChangeEvent, useEffect, useState } from 'react';
import { useGame } from 'hooks/playGames/useGame';
import LoaderWithBg from 'components/loader/loader';
import { grey } from '@mui/material/colors';
import { User } from 'types/user';
import styles from './searchGame.module.scss';

interface SearchGameProps {
  hideCategory: (isHide: boolean) => void;
  user: User | null;
}

const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root.Mui-selected, & .MuiPaginationItem-root.Mui-selected:hover': {
      color: color.mainOrange,
      backgroundColor: 'unset',
    },
  },
  paper: {
    height: '40px',
    border: 'Thin solid white',
    backgroundColor: 'unset!important',
    '& svg': {
      fill: 'white',
    },
  },
}));

export const SearchGame = ({ hideCategory, user }: SearchGameProps) => {
  const { t } = useTranslation('common');
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const { isLoading, searchGame } = useGame();
  const [debouncedValue, setDebouncedValue] = useState('');
  const [gameName, setGameName] = useState<string | null>(null);
  const [gameList, setGameList] = useState<IGame[]>([]);
  const [showMessage, setShowMessage] = useState('');
  const classes = useStyles();

  const handleOnClickMore = (event: React.ChangeEvent<unknown>) => {
    if (totalPage > 1) {
      setPage((value) => (value + 1 <= totalPage ? value + 1 : value));
    }
  };

  const handleGameSearch = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    setGameName(value);
  };

  useEffect(() => {
    setPage(1);
    const delay = 500;
    const timeoutId = setTimeout(() => {
      setDebouncedValue(gameName ?? '');
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [gameName]);

  useEffect(() => {
    const searchData = async () => {
      const forPublicReguest = !user ? '/public?' : undefined;
      const query = `&isMobile=false&pageSize=20&page=${page}`;
      const result = await searchGame(debouncedValue, query, forPublicReguest);
      if (result?.status === 200) {
        setShowMessage('');
        if (page === 1) {
          setGameList(result.data.games);
        } else {
          setGameList((games) => [...games, ...result.data.games]);
        }
        setTotalPage(result.data.totalPages);

        hideCategory(!!result.data.games.length);
      } else {
        if (!result.data.games) {
          setShowMessage('nothingFound');
          setGameList([]);
        }
      }
    };

    if (debouncedValue) {
      searchData();
    } else {
      setGameList([]);
      hideCategory(false);
      setShowMessage('');
    }
  }, [debouncedValue, page]);

  return (
    <Box className={styles.main}>
      {isLoading ? <LoaderWithBg isOpen={isLoading} /> : null}
      <Box
        sx={{
          width: '100%',
          padding: '12px 42px',
          display: { xs: 'none', md: 'flex' },
        }}
      >
        <Paper
          className={classes.paper}
          sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
        >
          <InputBase
            size="small"
            fullWidth
            sx={{ ml: 1, flex: 1 }}
            placeholder={t('searchGame')}
            inputProps={{ 'aria-label': 'search game' }}
            onChange={handleGameSearch}
          />
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
      </Box>
      {gameList.length ? (
        <Box sx={{ width: '100%', display: 'flex' }}>
          <Box className={styles.games_container}>
            <Box className={styles.games}>
              {gameList?.map(
                ({ image, id, providerId, isFavorite, isUnliked }: any, index: number) => {
                  return (
                    <GameCard
                      key={index}
                      imageUrl={image}
                      gameId={id}
                      className={styles.gameItem}
                      providerId={providerId}
                      isFavorite={isFavorite}
                      isUnliked={isUnliked}
                    />
                  );
                },
              )}
            </Box>
          </Box>
        </Box>
      ) : null}
      {page < totalPage && gameList.length ? (
        <Stack className={styles.showMore} spacing={2}>
          <Button onClick={handleOnClickMore}>{t('showMore')}</Button>
        </Stack>
      ) : null}
      {showMessage ? (
        <Typography
          sx={{ mt: 2, mb: 2, display: { xs: 'none', md: 'block' }, color: grey[400] }}
          textAlign={'center'}
        >
          {t(showMessage)}
        </Typography>
      ) : null}
    </Box>
  );
};
