import { AlertColor, Box, Button, Tab, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { renderItem } from 'components/deposit/deposit';
import { isCardNumber, isNumber } from 'utils/validations/validationTypes';
import { useTransaction } from 'hooks/transactions/useTransaction';
import { useSelector } from 'react-redux';
import Result from 'components/alert/alert';
import { usePayment } from 'hooks/payment/usePayment';
import { FIELD_NAME, IPayMethods } from 'types/payment';
import { RESULT } from 'types/common';
import LoaderWithBg from 'components/loader/loader';
import common from 'styles/colors.module.scss';
import styles from '../deposit/deposit.module.scss';

const fields = {
  [FIELD_NAME.MIN]: true,
  [FIELD_NAME.CARD]: true,
};

function Withdraw() {
  const { t } = useTranslation('deposit');
  const { isLoading: isLoadingWithdraw, payOut, error, resetError } = usePayment();
  const { t: commonT } = useTranslation('common');
  const { t: paymentT } = useTranslation('payment');
  const { t: validationT } = useTranslation('validation');
  const [value, setValue] = useState('0');
  const [activePayment, setAtivePayment] = useState<IPayMethods>();
  const [quantity, setQuantity] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [criptoLimit, setCriptoLimit] = useState(7);
  const userData = useSelector<any>((state) => state.userData) as any;
  const [validFields, setValidFields] = useState({ ...fields });
  const [requestMessage, setRequestMessage] = useState<string>('');
  const [type, setType] = useState<AlertColor>('success');
  const { isLoading, methodsList, getAllPayOutMethods } = useTransaction();
  const [errorName, setErrorName] = useState('');

  const walletAmount = userData?.balance.filter((el: any) => el.id === 3)?.[0]?.amount;

  const resetValidFields = (name: string) => {
    setValidFields((fields) => ({
      ...fields,
      [name]: true,
    }));
    setErrorName('');
  };

  const handleOnClose = () => {
    resetError();
    setRequestMessage('');
  };

  const checkValidation = (activePayment: IPayMethods, name: string, value: string) => {
    let isValid = true;

    switch (name) {
      case FIELD_NAME.CARD:
        isValid = value.length === 19;
        break;
      case FIELD_NAME.MIN:
        if (+value >= activePayment?.sitePayOutMethods?.[0]?.maxValue) {
          setErrorName('isBiggerMaxVal');
        } else if (+value <= activePayment?.sitePayOutMethods?.[0]?.minValue) {
          setErrorName('isSmallerMinVal');
        } else {
          setErrorName('notEnoughMoney');
        }
        isValid =
          +value >= activePayment?.sitePayOutMethods?.[0]?.minValue &&
          +value <= activePayment?.sitePayOutMethods[0]?.maxValue &&
          +quantity <= walletAmount;
        break;
      default:
    }
    return isValid;
  };

  const handleOnBlur = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    name: string,
  ) => {
    const { value } = e.target;
    if (activePayment && value != '') {
      if (name === FIELD_NAME.CARD) {
        const num = value.split('');
        for (let i = 4; i < num.length - 1; i += 5) {
          if (num[i] !== '-') {
            num.splice(i, 0, '-');
          }
        }
        if (checkValidation(activePayment, name, num.join(''))) {
          setCardNumber(num.join(''));
        } else {
          setValidFields((fields) => ({
            ...fields,
            [FIELD_NAME.CARD]: false,
          }));
        }
      } else {
        validate(value, name);
      }
    }
  };

  const validate = (value: any, name: string) => {
    if (activePayment) {
      setValidFields((fields) => ({
        ...fields,
        [name]: checkValidation(activePayment, name, value),
      }));
    }
  };

  const handleOnClick = async () => {
    if (activePayment && +quantity !== 0 && cardNumber !== '' && +quantity <= walletAmount) {
      const body = { amount: +quantity, requisites: cardNumber.split('-').join(''), comment: null };
      const res = await payOut(activePayment?.id, body);
      if (res?.status === 200) {
        setRequestMessage(paymentT(RESULT.PENDING_W));
        setType(RESULT.SUCCESS);
        setQuantity('');
        setCardNumber('');
      }
    } else {
      setValidFields((fields) => ({
        ...fields,
        [FIELD_NAME.CARD]: cardNumber !== '',
        [FIELD_NAME.MIN]: +quantity !== 0 && +quantity <= walletAmount,
      }));
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setQuantity('');
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    if (isNumber(value)) {
      setQuantity(value);
    }
    resetValidFields(FIELD_NAME.MIN);
  };

  const handleOnPaste = (e: any) => {
    const text = e.clipboardData.getData('Text');
    if (isCardNumber(text)) {
      const value = text.split('');
      for (let i = 4; i < value.length - 1; i += 5) {
        if (value[i] !== '-') {
          value.splice(i, 0, '-');
        }
      }
      setCardNumber(value.join(''));
    }
  };

  const handleOnChangeCard = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    const trimmedValue = value.trim();
    if ((isCardNumber(trimmedValue) || value === '') && trimmedValue.length <= 19) {
      if (trimmedValue.length === 4 || trimmedValue.length === 9 || trimmedValue.length === 14) {
        setCardNumber(trimmedValue + '-');
      } else if (
        trimmedValue.length === 5 ||
        trimmedValue.length === 10 ||
        trimmedValue.length === 15
      ) {
        const str = trimmedValue.slice(0, -1);
        setCardNumber(str);
      } else {
        setCardNumber(trimmedValue);
      }
    }
    resetValidFields(FIELD_NAME.CARD);
  };

  useEffect(() => {
    const isCripto = !!+value;
    getAllPayOutMethods(`isCrypto=${isCripto}`);
  }, [value]);

  useEffect(() => {
    if (methodsList.length) {
      setAtivePayment(methodsList[0]);
    }
  }, [methodsList]);

  return (
    <Box className={styles.main_area}>
      {isLoadingWithdraw ? <LoaderWithBg isOpen={isLoadingWithdraw} /> : null}
      <Box className={styles.main_title}>{t('withdraw')}</Box>
      <Box className={styles.methods_block}>
        <Box className={styles.header}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box className={styles.title}>{paymentT('title')}</Box>
            <Box className={styles.bullet} />
          </Box>
          <Box className={styles.current_amount}>
            <Typography>{t('currAmount')}:</Typography>
            <Typography sx={{ color: common.mainOrange, ml: 2, fontWeight: 800 }}>
              {walletAmount} {userData?.currency?.code}
            </Typography>
          </Box>
        </Box>
        {methodsList.length > criptoLimit ? (
          <Box className={styles.show_more} onClick={() => setCriptoLimit(10)}>
            {commonT('showMore')}
          </Box>
        ) : null}
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange}>
              {[{ name: 'money' }, { name: 'crypto' }].map(({ name }, index) => {
                return (
                  <Tab
                    key={index}
                    value={index + ''}
                    label={t(name)}
                    sx={{ alignItems: 'start' }}
                  />
                );
              })}
            </TabList>
          </Box>

          <TabPanel value="0" className={styles.own_tab}>
            <Box className={styles.deposit_block}>
              {isLoading ? (
                <Box className={styles.loading}>{commonT('loading')}</Box>
              ) : (
                methodsList.map((item: IPayMethods, index: number) => {
                  return index < criptoLimit
                    ? renderItem(
                        activePayment?.id === item.id,
                        item,
                        t('min'),
                        index,
                        item.sitePayOutMethods[0].minValue,
                        item.sitePayOutMethods[0].currencyCode,
                        setAtivePayment,
                      )
                    : null;
                })
              )}
            </Box>
          </TabPanel>
          <TabPanel value="1" className={styles.own_tab}>
            <Box className={styles.deposit_block}>
              {isLoading ? (
                <Box className={styles.loading}>{commonT('loading')}</Box>
              ) : (
                methodsList.map((item: IPayMethods, index: number) => {
                  return index < criptoLimit
                    ? renderItem(
                        activePayment?.id === item.id,
                        item,
                        t('min'),
                        index,
                        item.sitePayOutMethods[0].minValue,
                        item.sitePayOutMethods[0].currencyCode,
                        setAtivePayment,
                      )
                    : null;
                })
              )}
            </Box>
          </TabPanel>
        </TabContext>
        <Box className={styles.promo}>
          <TextField
            label={t('count', {
              val: activePayment?.sitePayOutMethods[0].minValue,
              curr: `${userData?.currency?.code}`,
            })}
            size="small"
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={quantity}
            onChange={handleOnChange}
            onBlur={(e) => handleOnBlur(e, FIELD_NAME.MIN)}
            error={!validFields[FIELD_NAME.MIN]}
          />
          <Box className={styles.currency_name}>{userData?.currency?.code} </Box>
        </Box>
        {!validFields[FIELD_NAME.MIN] && errorName === 'isSmallerMinVal' ? (
          <Typography className={styles.error}>
            {validationT('minMustBe', { length: activePayment?.sitePayOutMethods[0].minValue })}{' '}
            {commonT('forWithdraw')}
          </Typography>
        ) : !validFields[FIELD_NAME.MIN] && errorName === 'isBiggerMaxVal' ? (
          <Typography className={styles.error}>
            {validationT('maxMustBe', { length: activePayment?.sitePayOutMethods[0].maxValue })}{' '}
            {commonT('forWithdraw')}
          </Typography>
        ) : !validFields[FIELD_NAME.MIN] && errorName === 'notEnoughMoney' ? (
          <Typography className={styles.error}>{commonT('notEnoughMoney')}</Typography>
        ) : null}
      </Box>

      <Box className={styles.promo_block}>
        <Box className={styles.promo}>
          <TextField
            size="small"
            fullWidth
            label={value === '1' ? commonT('cryptoWallet') : commonT('cardNum')}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={cardNumber}
            onPaste={handleOnPaste}
            onChange={handleOnChangeCard}
            onBlur={(e) => handleOnBlur(e, FIELD_NAME.CARD)}
            error={!validFields[FIELD_NAME.CARD]}
            inputProps={{
              autoComplete: 'off',
            }}
          />
        </Box>
      </Box>
      <Box sx={{ mb: 3 }}>
        {!validFields[FIELD_NAME.CARD] ? (
          <Typography className={styles.error}>
            {validationT('minLengthofCard', { length: 16 })}
          </Typography>
        ) : null}
        <Button
          sx={{ mt: 2, backgroundColor: common.mainOrange }}
          fullWidth
          variant="contained"
          onClick={handleOnClick}
          disabled={
            !validFields[FIELD_NAME.MIN] || !validFields[FIELD_NAME.CARD] || isLoadingWithdraw
          }
        >
          {commonT('sendRequest')}
        </Button>
      </Box>
      <Result
        style={{ mt: 2 }}
        message={requestMessage || commonT(error)}
        type={error ? RESULT.ERROR : type}
        isShow={!!(requestMessage || error)}
        hasAction
        onClose={handleOnClose}
      />
    </Box>
  );
}

export default Withdraw;
