export interface IFormData {
  login: string;
  password: string;
  confirm: string;
  currencyId: number;
  email: string;
  phone: string;
  promo: string | null;
  terms: boolean;
  /**TODO: write real types after getting all final data from BE */
  [key: string]: any;
}

export interface IErrors {
  [key: string]: string;
}

export interface ICheckBoxes {
  id: string;
  name: string;
  code: string;
  icon: string;
}

export enum FIELD_NAME {
  LOGIN = 'login',
  EMAIL = 'email',
  PASSWORD = 'password',
  CONFIRM_PASS = 'confirm',
  PROMO_CODE = 'promo',
  PHONE = 'phone',
}
